<template>
  <div>
    <v-data-table
      dense
      :headers="headersShipTo"
      :items="dessertsShipTo"
      :options.sync="optionsShipTo"
      :server-items-length="totalDessertsShipTo"
      :loading="loadingShipTo"
      v-model="form.shipto"
      :single-select="singleSelectShipTo"
      item-key="id"
      show-select
    >
      <template v-slot:top>
        <v-switch
          v-model="singleSelectShipTo"
          label="Single select"
          class="pa-3"
        ></v-switch>
      </template>
      <template v-slot:[`item.sales_district_id`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.sales_district_id }}</p>
      </template>
      <template v-slot:[`item.custgroup`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.custgroup }}</p>
      </template>
      <template v-slot:[`item.district`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.district }}</p>
      </template>
      <template v-slot:[`item.accountnum`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.accountnum }}</p>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.name }}</p>
      </template>
      <template v-slot:[`item.aliases`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.aliases }}</p>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
computed: {
    ...mapState("user", {
      singleSelectShipTo: (state) => state.singleSelectShipTo,
      form: (state) => state.form,
      totalDessertsShipTo: (state) => state.totalDessertsShipTo,
      dessertsShipTo: (state) => state.dessertsShipTo,
      loadingShipTo: (state) => state.loadingShipTo,
      optionsShipTo: (state) => state.optionsShipTo,
      headersShipTo: (state) => state.headersShipTo,
    }),
    singleSelectShipTo: {
      get: function () {
        return this.$store.state.user.singleSelectShipTo;
      },
      set: function (value) {
        this.$store.commit("user/SET_SINGLE_SELECTED_SHIPTO", value);
      },
    },
    selectedShipTo: {
      get: function () {
        return this.$store.state.user.selectedShipTo;
      },
      set: function (value) {
        this.$store.commit("user/SET_SELECTED_SHIPTO", value);
      },
    },
    optionsShipTo: {
      get: function () {
        return this.$store.state.user.optionsShipTo;
      },
      set: function (value) {
        this.$store.commit("user/SET_OPTIONS_SHIPTO", value);
      },
    },
    loadingShipTo: {
      get: function () {
        return this.$store.state.user.loadingShipTo;
      },
      set: function (value) {
        this.$store.commit("user/SET_LOADING_SHIPTO", value);
      },
    },
  },
  watch: {
    optionsShipTo: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    ...mapActions("user", ["getshipto"]),
    getDataFromApi() {
      this.getshipto().then((e)=>{
        if (e != 200) {
          this.logout().then((r) => {
            this.$router.push({ name: 'auth.login' }) 
          })
        }
      });
    },
  },
};
</script>