<template>
  <v-card>
    <v-data-table
      :headers="headersDetailsRO"
      :items="dessertsDetailsRO"
      :options.sync="optionsDetailsRO"
      :server-items-length="totalDessertsDetailsRO"
      :loading="loadingTableDetailsRO"
      :single-expand="singleExpandDetailsRO"
      :expanded.sync="expandedDetailsRO"
      item-key="id"
      show-expand
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ma-2" fab x-small v-bind="attrs" v-on="on" @click="$router.go(-1)">
                <v-icon>mdi-keyboard-backspace</v-icon>
              </v-btn>
            </template>
            <span>Back pages</span>
          </v-tooltip>
          <v-text-field
            v-model="searchDetailsRO"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-switch
            v-model="singleExpandDetailsRO"
            label="Single expand"
            class="mt-2"
          ></v-switch>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container>
            <v-simple-table>
              <template v-slot:top>
                <v-toolbar flat>
                    <p class="font-weight-black">ITEM ID : {{ item.item_id }}</p>
                  <v-spacer></v-spacer>
                    <p class="font-weight-black">CATEGORY : {{ item.kategori }}</p>
                  <v-spacer></v-spacer>
                    <p class="font-weight-black">SHIP TO : {{ item.shifto }}</p>
                  <v-spacer></v-spacer>
                    <p class="font-weight-black">MONTH : {{ item.periode }}</p>
                </v-toolbar>
              </template>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Product Name</th>
                    <th class="text-left">QTY-box</th>
                    <th class="text-left">QTY</th>
                    <th class="text-left">Amount</th>
                    <th class="text-left">Price</th>
                    <th class="text-left">Disc</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']">{{ item.desc === null ? "null" : item.desc }}</td>
                    <td v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']">{{ item.box_qty === null ? "null" : item.box_qty }}</td>
                    <td v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']">{{ item.qty_1 === null ? "null" : item.qty }}</td>
                    <td v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']">
                      {{ item.amount_1 === null ? "null" : 'Rp. '+item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                    </td>
                    <td v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']">{{ item.price_1 === null ? "null" : 'Rp. '+item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</td>
                    <td v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']">{{ item.disc_1 === null ? "null" : item.disc }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
        </td>
      </template>
      <template v-slot:[`item.item_id`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.item_id }}</p>
      </template>
      <template v-slot:[`item.desc`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.desc }}</p>
      </template>
      <template v-slot:[`item.box_qty`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.box_qty }}</p>
      </template>
      <template v-slot:[`item.mtp_qty`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.mtp_qty }}</p>
      </template>
      <template v-slot:[`item.qty`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.qty }}</p>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">
          Rp. {{ parseFloat(item.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
        </p>
      </template>
      <template v-slot:[`item.disc`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">
          {{ parseFloat(item.disc) }}%
        </p>
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">
          Rp. {{ parseFloat(item.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
        </p>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState(["loading"]),
    ...mapState("listro", {
      searchDetailsRO: (state) => state.searchDetailsRO,
      loadingTableDetailsRO: (state) => state.loadingTableDetailsRO,
      totalDessertsDetailsRO: (state) => state.totalDessertsDetailsRO,
      dessertsDetailsRO: (state) => state.dessertsDetailsRO,
      optionsDetailsRO: (state) => state.optionsDetailsRO,
      headersDetailsRO: (state) => state.headersDetailsRO,
      expandedDetailsRO: (state) => state.expandedDetailsRO,
      singleExpandDetailsRO: (state) => state.singleExpandDetailsRO,
    }),
    expandedDetailsRO: {
      get: function () {
        return this.$store.state.listro.expandedDetailsRO;
      },
      set: function (value) {
        this.$store.commit("listro/EXPANDED_DETAILS_RO", value);
      },
    },
    singleExpandDetailsRO: {
      get: function () {
        return this.$store.state.listro.singleExpandDetailsRO;
      },
      set: function (value) {
        this.$store.commit("listro/SINGLEXPAND_DETAILS_RO", value);
      },
    },
    optionsDetailsRO: {
      get: function () {
        return this.$store.state.listro.optionsDetailsRO;
      },
      set: function (value) {
        this.$store.commit("listro/SET_OPTIONS_DETAIL_RO", value);
      },
    },
    searchDetailsRO: {
      get: function () {
        return this.$store.state.listro.searchDetailsRO;
      },
      set: function (value) {
        this.$store.commit("listro/SET_SEARCH_DETAIL_RO", value);
      },
    },
  },
  watch: {
    optionsDetailsRO: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    searchDetailsRO() {
      this.getDataFromApi();
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    ...mapActions("listro", ["IndexDetailRO"]),
    getDataFromApi() {
      let id = this.$route.params.id;
      this.IndexDetailRO(id);
    },
  },
};
</script>