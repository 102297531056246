import $axios from '../core/api.js'

const state = () => ({
    // STATE TABLE STARTED
    totalDesserts: 0,
    desserts: [],
    search: '',
    options: {},
    headers: [
        {
            text: 'Role Name',
            align: 'start',
            sortable: false,
            value: 'name',
        },
        { text: 'Guard', value: 'guard_name' },
        { text: 'Created at', value: 'created_at' },
        { text: 'Updated at', value: 'updated_at' },
        { text: '#', value: 'actions', sortable: false },
    ],
    // STATE TABLE ENDED
    // STATE FORM STARTED
    dialog: {
        show: false,
        param: 0
    },
    form: {
        name: '',
        permission: [],
    },
    permission: [],
    permission_page: 1,
    permission_length: 0,
    permission_total: 0,
    // STATE FORM ENDED
})

const mutations = {
    // MUTATION TABLE STARTED
    SET_DATATABLES(state, payload) {
        state.totalDesserts = payload.totalDesserts,
            state.desserts = payload.desserts
    },
    SET_OPTIONS(state, payload) {
        state.options = payload
    },
    SET_SEARCH(state, payload) {
        state.search = payload
    },
    SET_DIALOG(state, payload) {
        state.dialog = {
            show: payload.show,
            id: payload.id
        }
    },
    SET_PERMISSION(state, payload) {
        state.permission = payload.permission
        state.permission_length = payload.permission_length
        state.permission_total = payload.permission_total
    },
    SET_PERMISSION_PAGE(state, payload) {
        state.permission_page = payload
    },
    SET_FORM(state, payload) {
        state.form = {
            name: payload.name,
            permission: payload.permission,
        }
    },
    CLEAR_FORM(state, payload) {
        state.form = {
            name: '',
            permission: '',
        }
    },
}

const actions = {
    getPermission({ commit, state }) {
        return new Promise((resolve, reject) => {
            $axios.get(`/roles-permission?page=${state.permission_page}&perpage=5&search=`)
                .then((response) => {
                    if (response.status === 200 && response.data.status === "Token is Expired") {
                        setTimeout(() => {
                            localStorage.setItem('token', null)
                            commit('SET_TOKEN', null, { root: true })
                        }, 1000)
                        resolve(401)
                    } else {
                        const dataPermission = {
                            permission: response.data.data,
                            permission_length: response.data.meta.last_page,
                            permission_total: response.data.meta.total,
                        };
                        commit('SET_PERMISSION', dataPermission)
                        resolve(response.status)
                    }
                })
        })
    },
    Index({ commit, state }) {
        commit('SET_LOADING', true, { root: true })
        return new Promise((resolve, reject) => {
            const { sortBy, sortDesc, page, itemsPerPage } = state.options;
            $axios
                .get(`/roles?page=${page}&perpage=${itemsPerPage}&search=${state.search}`)
                .then(function (response) {
                    if (response.status === 200 && response.data.status === "Token is Expired") {
                        setTimeout(() => {
                            localStorage.setItem('token', null)
                            commit('SET_TOKEN', null, { root: true })
                        }, 1000)
                        resolve(401)
                    } else {
                        const setdata = {
                            desserts: response.data.data,
                            totalDesserts: response.data.meta.total
                        };
                        commit('SET_DATATABLES', setdata)
                        commit('SET_LOADING', false, { root: true })
                        resolve(response.status)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    },
    createOrUpdate({ dispatch, commit, state }, payload) {
        return new Promise((resolve, reject) => {
            if (payload === undefined) {
                $axios.post(`/roles`, state.form)
                    .then(function (response) {
                        if (response.status === 200 && response.data.status === "Token is Expired") {
                            setTimeout(() => {
                                localStorage.setItem('token', null)
                                commit('SET_TOKEN', null, { root: true })
                            }, 1000)
                            resolve(401)
                        } else {
                            dispatch('Index')
                            resolve(response.status)
                            commit('SET_ALERTBANNER', response.data, { root: true })
                            setTimeout(() => {
                                commit('CLEAR_ALERTBANNER', response.data, { root: true })
                            }, 3000);
                        }
                    })
                    .catch(function (error) {
                        if (error.response) {
                            commit('SET_ERRORS', error.response.data.errors, { root: true })
                            resolve(error.response.status)
                        }
                    });
            } else {
                $axios.put(`/roles/${payload}`, state.form)
                    .then(function (response) {
                        if (response.status === 200 && response.data.status === "Token is Expired") {
                            setTimeout(() => {
                                localStorage.setItem('token', null)
                                commit('SET_TOKEN', null, { root: true })
                            }, 1000)
                            resolve(401)
                        } else {
                            dispatch('Index')
                            resolve(response.status)
                            commit('SET_ALERTBANNER', response.data, { root: true })
                            setTimeout(() => {
                                commit('CLEAR_ALERTBANNER', response.data, { root: true })
                            }, 3000);
                        }
                    })
                    .catch(function (error) {
                        if (error.response) {
                            commit('SET_ERRORS', error.response.data.errors, { root: true })
                            resolve(error.response.status)
                        }
                    });
            }
        })
    },
    show({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`/roles/${payload}/edit`)
                .then((response) => {
                    if (response.status === 200 && response.data.status === "Token is Expired") {
                        setTimeout(() => {
                            localStorage.setItem('token', null)
                            commit('SET_TOKEN', null, { root: true })
                        }, 1000)
                        resolve(401)
                    } else {
                        commit('SET_FORM', response.data.data)
                        resolve(response.status)
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        console.log(error.response.status)
                    }
                });
        })
    },
    delete({ dispatch, commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post(`/roles/destroy`, payload)
                .then((response) => {
                    if (response.status === 200 && response.data.status === "Token is Expired") {
                        setTimeout(() => {
                            localStorage.setItem('token', null)
                            commit('SET_TOKEN', null, { root: true })
                        }, 1000)
                        resolve(401)
                    } else {
                        dispatch('Index')
                        resolve(response.status)
                        commit('SET_ALERTBANNER', response.data, { root: true })
                        setTimeout(() => {
                            commit('CLEAR_ALERTBANNER', response.data, { root: true })
                        }, 3000);
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        console.log(error.response.status)
                    }
                });
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}

