import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import App from './components/App.vue'

// component/core
import Permissions from './components/mixins/Permission.js'
import router from './components/core/router.js'
import store from './components/core/store.js'
// component/core

Vue.mixin(Permissions);
Vue.use(Vuetify)
new Vue({
    vuetify: new Vuetify({
        theme: {
            dark: true,
            themes: {
                light: {
                  primary: '#1565C0',
                  secondary: '#C2185B',
                  accent: '#4DB6AC',
                  error: '#EF5350',
                  info: '#42A5F5',
                  success: '#77D970',
                  warning: '#FEE440',
                  chart: '#FFFFFF',
                  card: '#FFFFFF',
                  cardfo: '#039BE5',
                  cardro: '#00ACC1',
                  drawer: '#111827',
                  appbar: '#FFFFFF',
                  mainBackground: '#f2f4f4',
                },
                dark: {
                  primary: '#0288D1',
                  secondary: '#006064',
                  accent: '#00695C',
                  error: '#B71C1C',
                  info: '#0D47A1',
                  success: '#77D970',
                  warning: '#FFFD95',
                  chart: '#F5F5F5',
                  card: '#212121',
                  cardfo: '#F57F17',
                  cardro: '#BF360C',
                  drawer: '#212121',
                  appbar: '#1A1A1A',
                  mainBackground: '#1A1A1A',
                },
            },
        },
        icons: {
            iconfont: 'mdi',
        },
    }),
    el: '#app',
    router,
    store,
    components: { App },
    computed: {
        ...mapGetters(['isAuth'])
    },
    methods: {
        ...mapActions('auth', ['getUserLogin', 'getMenu'])
    },
    watch: {
        isAuth() {
            if (this.isAuth === true) {
                this.getUserLogin().then((e) => {
                    if (e != 200) {
                        this.$router.push({ name: 'auth.login' })
                    }
                })
                this.getMenu().then((e) => {
                    if (e != 200) {
                        this.$router.push({ name: 'auth.login' })
                    }
                })
            }
        }
    },
    created() {
        if (this.isAuth === true) {
            this.getUserLogin().then((e) => {
                if (e != 200) {
                    this.$router.push({ name: 'auth.login' })
                }
            })
            this.getMenu().then((e) => {
                if (e != 200) {
                    this.$router.push({ name: 'auth.login' })
                }
            })
        }
    }
});
