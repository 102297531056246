import $axios from '../core/api.js'

const state = () => ({
  // STATE TABLE SHIPTO
  singleSelectShipTo: false,
  selectedShipTo: [],
  totalDessertsShipTo: 0,
  dessertsShipTo: [],
  loadingShipTo: true,
  optionsShipTo: {},
  headersShipTo: [
    {
      text: "DISTRICT ID",
      align: "start",
      sortable: false,
      value: "sales_district_id",
    },
    { text: "CUST GROUP", value: "custgroup" },
    { text: "DISTRICT", value: "district" },
    { text: "ACCOUNT ID", value: "accountnum" },
    { text: "COMPANY", value: "name" },
    { text: 'NAME ALIASES', value: 'aliases' },
  ],
  // STATE TABLE SHIPTO

  // STATE TABLE STARTED
  totalDesserts: 0,
  desserts: [],
  search: '',
  options: {},
  headers: [
    {
      text: 'Name',
      align: 'start',
      sortable: false,
      value: 'name',
    },
    { text: 'Email', value: 'email' },
    { text: 'User Type', value: 'type' },
    { text: 'Created At', value: 'created_at' },
    { text: '#', value: 'actions', sortable: false },
  ],
  // STATE TABLE ENDED
  // STATE FORM STARTED
  dialog: {
    show: false,
    param: 0
  },
  itemShipto: [],
  form: {
    name: '',
    email: '',
    password: '',
    role: '',
    type: '',
    r: '',
    accountID: '',
    shipto: [],
  },
  roles: [],
  // STATE FORM ENDED
})

const mutations = {
  // MUTATION TABLE STARTED
  SET_DATATABLES(state, payload) {
    state.totalDesserts = payload.totalDesserts,
      state.desserts = payload.desserts
  },
  SET_OPTIONS(state, payload) {
    state.options = payload
  },
  SET_SEARCH(state, payload) {
    state.search = payload
  },
  // MUTATION TABLE ENDED

  // MUTATION TABLE SHIPTO STARTED
  SET_DATATABLES_SHIPTO(state, payload) {
    state.totalDessertsShipTo = payload.totalDessertsShipTo,
      state.dessertsShipTo = payload.dessertsShipTo
  },
  SET_OPTIONS_SHIPTO(state, payload) {
    state.optionsShipTo = payload
  },
  SET_LOADING_SHIPTO(state, payload) {
    state.loadingShipTo = payload
  },
  SET_SINGLE_SELECTED_SHIPTO(state, payload) {
    state.singleSelectShipTo = payload
  },
  SET_SELECTED_SHIPTO(state, payload) {
    state.selectedShipTo = payload
  },
  // MUTATION TABLE SHIPTO ENDED
  SET_DIALOG(state, payload) {
    state.dialog = {
      show: payload.show,
      id: payload.id
    }
  },
  SET_ROLES(state, payload) {
    state.roles = payload
  },
  SET_FORM(state, payload) {
    state.form = {
      name: payload.name,
      email: payload.email,
      password: payload.password,
      role: payload.role,
      type: payload.type,
      r: payload.r,
      accountID: payload.accountID,
      shipto: payload.shipto,
    }
  },
  CLEAR_FORM(state) {
    state.form = {
      name: '',
      email: '',
      password: '',
      role: '',
      type: '',
      r: '',
      accountID: '',
      shipto: [],
    }
  },
}

const actions = {
  getHistory({ commit },payload) {
    return new Promise((resolve) => {
      console.log(payload);
    $axios
      .get(`/history/${payload.id}?page=${payload.pages}`)
      .then(function (response) {
          if (response.status === 200 && response.data.status === "Token is Expired") {
              setTimeout(() => {
                  localStorage.setItem('token', null)
                  commit('SET_TOKEN', null, { root: true })
              }, 1000)
              resolve(401)
          } else {
              resolve(response.data)
          }
      })
      .catch(function (error) {
          console.log(error);
      });
    });
  },
  getroles({ commit }) {
    return new Promise((resolve, reject) => {
      $axios.get(`/users-roles`)
        .then((response) => {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            commit('SET_ROLES', response.data.data)
            resolve(response.status)
          }
        })
    })
  },
  getshipto({ commit }) {
    return new Promise((resolve) => {
      $axios.get(`/users-shipto`)
        .then((response) => {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            commit('SET_ITEM_SHIPTO', response.data.data)
            resolve(response.status)
          }
        })
    })
  },
  getshipto({ commit, state }) {
    commit('SET_LOADING_SHIPTO', true)
    return new Promise((resolve) => {
      const { page, itemsPerPage } = state.optionsShipTo;
      $axios
        .get(`/users-shipto?page=${page}&perpage=${itemsPerPage}`)
        .then(function (response) {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            const setdata = {
              dessertsShipTo: response.data.data,
              totalDessertsShipTo: parseInt(response.data.meta.total)
            };
            commit('SET_DATATABLES_SHIPTO', setdata)
            commit('SET_LOADING_SHIPTO', false)
            resolve(response.status)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  },
  Index({ commit, state }) {
    commit('SET_LOADING', true, { root: true })
    return new Promise((resolve) => {
      const { page, itemsPerPage } = state.options;
      $axios
        .get(`/users?page=${page}&perpage=${itemsPerPage}&search=${state.search}`)
        .then(function (response) {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            const setdata = {
              desserts: response.data.data,
              totalDesserts: response.data.meta.total
            };
            commit('SET_DATATABLES', setdata)
            commit('SET_LOADING', false, { root: true })
            commit('CLEAR_FORM')
            resolve(response.status)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  },
  RecycleBins({ commit },payload) {
    return new Promise((resolve) => {
      $axios
        .get(`/users-recyclebin?page=${payload}`).then(function (response) {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            resolve(response.data)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  },
  createOrUpdate({ dispatch, commit, state }, payload) {
    return new Promise((resolve) => {
      if (payload === undefined) {
        $axios.post(`/users`, state.form)
          .then(function (response) {
            if (response.status === 200 && response.data.status === "Token is Expired") {
              setTimeout(() => {
                localStorage.setItem('token', null)
                commit('SET_TOKEN', null, { root: true })
              }, 1000)
              resolve(401)
            } else {
              dispatch('Index')
              resolve(response.status)
              commit('SET_ALERTBANNER', response.data, { root: true })
              setTimeout(() => {
                commit('CLEAR_ALERTBANNER', response.data, { root: true })
              }, 3000);
            }
          })
          .catch(function (error) {
            if (error.response) {
              commit('SET_ERRORS', error.response.data.errors, { root: true })
              resolve(error.response.status)
            }
          });
      } else {
        $axios.patch(`/users/${payload}`, state.form)
          .then(function (response) {
            if (response.status === 200 && response.data.status === "Token is Expired") {
              setTimeout(() => {
                localStorage.setItem('token', null)
                commit('SET_TOKEN', null, { root: true })
              }, 1000)
              resolve(401)
            } else {
              dispatch('Index')
              resolve(response.status)
              commit('SET_ALERTBANNER', response.data, { root: true })
              setTimeout(() => {
                commit('CLEAR_ALERTBANNER', response.data, { root: true })
              }, 3000);
            }
          })
          .catch(function (error) {
            if (error.response) {
              commit('SET_ERRORS', error.response.data.errors, { root: true })
              resolve(error.response.status)
            }
          });
      }
    })
  },
  show({ commit }, payload) {
    return new Promise((resolve) => {
      $axios.get(`/users/${payload}/edit`)
        .then((response) => {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            const setForm = {
              name: response.data.name,
              email: response.data.email,
              password: response.data.password,
              role: parseInt(response.data.role),
              type: response.data.type,
              r: response.data.roda,
              accountID: response.data.accountnum,
              shipto: response.data[0].shipto,
            }
            commit('SET_FORM', setForm)
            resolve(response.status)
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status)
          }
        });
    })
  },
  delete({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      $axios.post(`/users/destroy`, payload)
        .then((response) => {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            dispatch('Index')
            resolve(response.status)
            commit('SET_ALERTBANNER', response.data, { root: true })
            setTimeout(() => {
              commit('CLEAR_ALERTBANNER', response.data, { root: true })
            }, 3000);
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status)
          }
        });
    })
  },
  restoreRecycle({dispatch}, payload) {
    return new Promise((resolve) => {
      $axios.get(`/users/restore/${payload}`)
        .then((response) => {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            dispatch('Index')
            resolve(response.status)
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status)
          }
        });
    })
  },
  deleteRecycle({dispatch}, payload) {
    return new Promise((resolve) => {
      $axios.get(`/users/forcedestroy/${payload}`)
        .then((response) => {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            dispatch('Index')
            resolve(response.status)
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status)
          }
        });
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}

