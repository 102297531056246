<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{
            id ? "Update roles" : "Create roles"
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field v-model="form.name" label="Roles Name" required>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-simple-table class="mt-5">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Permission</th>
                      <th class="text-left">Access</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in permission" :key="item.name">
                      <td>
                        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.name }}</p>
                      </td>
                      <td>
                        <v-switch
                          class="mb-4"
                          v-model="form.permission"
                          :value="item.id"
                          hide-details
                        >
                        </v-switch>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="text-center mt-5">
                <v-pagination
                  v-model="permission_page"
                  :length="permission_length"
                  :total-visible="permission_total"
                  circle
                ></v-pagination>
              </div>
            </v-container>
          </v-form>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="id ? update(id) : create()">
            {{ id ? "Updated" : "Created" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState(["loading", "errors"]),
    ...mapState("role", {
      dialog: (state) => state.dialog.show,
      id: (state) => state.dialog.id,
      form: (state) => state.form,
      permission: (state) => state.permission,
      permission_page: (state) => state.permission_page,
      permission_length: (state) => state.permission_length,
      permission_total: (state) => state.permission_total,
    }),
    permission_page: {
      get() {
        return this.$store.state.role.permission_page;
      },
      set(val) {
        this.$store.commit("role/SET_PERMISSION_PAGE", val);
      },
    },
    dialog: {
      get: function () {
        return this.$store.state.role.dialog.show;
      },
      set: function (value) {
        this.$store.commit("role/SET_DIALOG", { show: value });
      },
    },
    id: {
      get: function () {
        return this.$store.state.role.dialog.id;
      },
      set: function (value) {
        this.$store.commit("role/SET_DIALOG", { id: value });
      },
    },
  },
  watch: {
    permission_page() {
      this.getPermission();
    },
    dialog(n,o) {
      if (n === false) {
        this.$store.commit("role/CLEAR_FORM");
      }
    },
  },
  methods: {
    ...mapActions("role", ["createOrUpdate","getPermission"]),
    create() {
      this.createOrUpdate().then((response) => {
        if (response === 200) {
          this.$store.commit("role/SET_DIALOG", { show: false });
        }
      });
    },
    update(id) {
      this.createOrUpdate(id).then((response) => {
        if (response === 200) {
          this.$store.commit("role/SET_DIALOG", { show: false });
        }
      });
    },
  },
};
</script>