<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :options.sync="options"
      :server-items-length="totalDesserts"
      :loading="loadingTable"
    >
      <template v-slot:top>
        <v-toolbar flat class="card">
          <v-text-field
            label="Search"
            placeholder="Find by..."
            class="mt-4"
            dense
            v-model="search"
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">
          {{ new Date(item.updated_at).toUTCString() }}
        </p>
      </template>
      <template v-slot:[`item.no_order`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.no_order }}</p>
      </template>
      <template v-slot:[`item.periode`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.periode }}</p>
      </template>
      <template v-slot:[`item.shifto`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.shifto }}</p>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.type }}</p>
      </template>
      <template v-slot:[`item.files`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.files }}</p>
      </template>
      <template v-slot:[`item.state`]="{ item }">
        <v-chip :color="(item.state === 'undownloaded')?'red':'green'" dark>
          {{ item.state }}
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              @click="show(item.no_order)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-database-arrow-right</v-icon>
            </v-btn>
          </template>
          <span>Show data file</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              @click="downloaded(item.no_order)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Download data file .xlsx</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import xlsx from "xlsx";
export default {
  data: () => ({
    disabled: true,
    loadingDownload: false
  }),
  computed: {
    ...mapState(["loading"]),
    ...mapState("listfo", {
      breadcrumbs: (state) => state.breadcrumbs,
      picked: (state) => state.picked,
      search: (state) => state.search,
      loadingTable: (state) => state.loadingTable,
      totalDesserts: (state) => state.totalDesserts,
      desserts: (state) => state.desserts,
      options: (state) => state.options,
      headers: (state) => state.headers,
    }),
    options: {
      get: function () {
        return this.$store.state.listfo.options;
      },
      set: function (value) {
        this.$store.commit("listfo/SET_OPTIONS", value);
      },
    },
    search: {
      get: function () {
        return this.$store.state.listfo.search;
      },
      set: function (value) {
        this.$store.commit("listfo/SET_SEARCH", value);
      },
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search() {
      this.getDataFromApi();
    },
    pick() {
      this.getDataFromApi();
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    ...mapActions("listfo", ["Index","download"]),
    getDataFromApi() {
      this.Index();
    },
    show(e){
      this.$router.push({ name: 'orderlist.detail', params: {initial:'fo',id: e} })
    },
    downloaded(e){
      this.loadingDownload=true
      this.download(e).then((res)=>{
          let fileName=res.headers['content-type'].split('filename=')[1].split(';')[0];
          const url = URL.createObjectURL(new Blob([res.data], {
            type: 'application/vnd.ms-excel'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
        this.loadingDownload=false
      });
    }
  },
};
</script>