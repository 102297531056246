<template>
  <v-card class="rounded-xl card" flat>
    <v-tabs v-model="tab" centered icons-and-text>
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#tab-1">
        Fixed Order
        <v-icon>mdi-order-bool-descending-variant</v-icon>
      </v-tab>
      <v-tab href="#tab-2">
        Regular Order
        <v-icon>mdi-order-bool-descending</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-card flat>
          <v-card-text><app-list-fo /></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <v-card flat>
          <v-card-text><app-list-ro /></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import ListRO from "./ListRO";
import ListFO from "./ListFO";
export default {
  data() {
    return {
      tab: null,
    };
  },
  components:{
      'app-list-fo':ListFO,
      'app-list-ro':ListRO,
  }
};
</script>