var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"d-flex justify-content-end px-3 mt-3"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"divider":"/"}})],1),_vm._v(" "),_c('v-row',{attrs:{"justify":"center"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.desserts,"options":_vm.options,"server-items-length":_vm.totalDesserts,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.model_type",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v(_vm._s(item.model_type))])]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v(_vm._s(item.message))])]}},{key:"item.meta",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v(_vm._s(item.meta))])]}},{key:"item.performed_at",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v(_vm._s(item.performed_at))])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }