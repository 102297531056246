<template>
  <v-data-table
    dense
    v-model="selected"
    :single-select="select"
    item-key="ID"
    show-select
    show-expand
    :expanded.sync="expanded"
    :headers="headers"
    :items="desserts"
    :search="search"
    :options.sync="options"
    :server-items-length="totalDesserts"
    :loading="loading"
  >
    <template v-slot:top>
      <v-toolbar flat class="rounded-xl">
        <v-toolbar-title>Core</v-toolbar-title>
        <v-switch
          v-model="select"
          label="Select"
          class="pa-3 mt-7"
          color="primary"
        ></v-switch>
        <v-spacer />
        <v-switch
          v-model="singleExpand"
          label="Expand"
          class="pa-3 mt-7"
          color="primary"
        ></v-switch>
        <v-btn class="mx-2" fab x-small @click="create()" v-if="$can('shipmaster-create') && selected.length > 0" color="primary">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:[`item.ID`]="{ item }">
      <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.ID.toUpperCase() }}</p>
    </template>
    <template v-slot:[`item.DATAAREAID`]="{ item }">
      <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.DATAAREAID.toUpperCase() }}</p>
    </template>
    <template v-slot:[`item.SALESDISTRICTID`]="{ item }">
      <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.SALESDISTRICTID }}</p>
    </template>
    <template v-slot:[`item.CUSTGROUP`]="{ item }">
      <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.CUSTGROUP }}</p>
    </template>
    <template v-slot:[`item.ACCOUNTNUM`]="{ item }">
      <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.ACCOUNTNUM }}</p>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.NAME }}</p>
      </td>
    </template>
  </v-data-table>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    "app-alert": Alert,
  },
  data() {
    return {
    expanded: [],
      singleExpand: false,
      selected: [],
      select: false,
    };
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState("shiptoCore", {
      totalDesserts: (state) => state.totalDesserts,
      desserts: (state) => state.desserts,
      search: (state) => state.search,
      options: (state) => state.options,
      headers: (state) => state.headers,
    }),
    options: {
      get: function () {
        return this.$store.state.shiptoCore.options;
      },
      set: function (value) {
        this.$store.commit("shiptoCore/SET_OPTIONS", value);
      },
    },
    search: {
      get: function () {
        return this.$store.state.shiptoCore.search;
      },
      set: function (value) {
        this.$store.commit("shiptoCore/SET_SEARCH", value);
      },
    },
    loading: {
      get: function () {
        return this.$store.state.loading;
      },
      set: function (value) {
        this.$store.commit("SET_LOADING", value);
      },
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search() {
      this.getDataFromApi();
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    ...mapActions("shiptoCore", ["Index"]),
    ...mapActions("shipto", ["createfromCore"]),
    ...mapActions("auth", ["logout"]),
    getDataFromApi() {
      this.Index().then((e) => {
        if (e != 200) {
          this.logout().then((r) => {
            this.$router.push({ name: "auth.login" });
          });
        }
      });
    },
    create() {
      this.createfromCore(this.selected).then((e) => {
        console.log(e);
      });
    },
  },
};
</script>