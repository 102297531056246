import Vue from 'vue'
import Router from 'vue-router'
import Home from '../../pages/home/Index.vue'
import Account from '../../pages/account/Account.vue'
import History from '../../pages/history/History.vue'

import Auth from '../../pages/auth/Index.vue'
import Login from '../../pages/auth/Login.vue'
import Register from '../../pages/auth/Register.vue'

// users page component
import IndexUsers from '../../pages/ManageUsers/Index.vue'
import DataUsers from '../../pages/ManageUsers/Data.vue'
// users page component

// roles page component
import IndexRoles from '../../pages/ManageRoles/Index.vue'
import DataRoles from '../../pages/ManageRoles/Data.vue'
// roles page component

// menu page component
import IndexMenu from '../../pages/ManageMenu/Index.vue'
import DataMenu from '../../pages/ManageMenu/Data.vue'
// menu page component

// menu page component
import IndexPromotion from '../../pages/ManagePromotion/Index.vue'
import DataPromotion from '../../pages/ManagePromotion/Data.vue'
// menu page component

// permissions page component
import IndexPermission from '../../pages/ManagePermission/Index.vue'
import DataPermission from '../../pages/ManagePermission/Data.vue'
// permissions page component

// shipto page component
import IndexShipto from '../../pages/ManageShipto/Index.vue'
import DataShipto from '../../pages/ManageShipto/Data.vue'
import DataRecycleBin from '../../pages/ManageShipto/DataRecycleBin.vue'
// shipto page component

// shipto page component
import IndexOrder from '../../pages/OrderList/Index.vue'
import DataOrder from '../../pages/OrderList/Data.vue'
import DataDetailOrder from '../../pages/OrderList/DataDetail.vue'
// shipto page component

// bonus dealers quartals page component
import IndexQuartalsBonusDealers from '../../pages/BonusDealers/quartals/Index.vue'
import DataQuartalsBonusDealers from '../../pages/BonusDealers/quartals/Data.vue'
import DetailQuartalsBonusDealers from '../../pages/BonusDealers/quartals/Detail.vue'
import EditQuartalsBonusDealers from '../../pages/BonusDealers/quartals/Edit.vue'
// bonus dealers quartals page component
// bonus dealers semesters page component
import IndexSemestersBonusDealers from '../../pages/BonusDealers/semesters/Index.vue'
import DataSemestersBonusDealers from '../../pages/BonusDealers/semesters/Data.vue'
// bonus dealers semesters page component
// bonus dealers semesters page component
import IndexReportBonusDealers from '../../pages/BonusDealers/report/Index.vue'
import DataReportBonusDealers from '../../pages/BonusDealers/report/Data.vue'
// bonus dealers semesters page component
// bonus dealers semesters page component
import IndexNotificationBonusDealers from '../../pages/BonusDealers/notification/Index.vue'
import DataNotificationBonusDealers from '../../pages/BonusDealers/notification/Data.vue'
// bonus dealers semesters page component

import store from './store.js'
Vue.use(Router)
const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/auth',
            component: Auth,
            children: [
                {
                    path: 'login',
                    name: 'auth.login',
                    component: Login
                },
                {
                    path: 'register',
                    name: 'auth.register',
                    component: Register
                },
                {
                    path: 'loginsocial/:token',
                    name: 'auth.loginsocial',
                    component: Login
                },
            ]
        },
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                requiresAuth: true,
                title: 'Home'
            }
        },
        {
            path: '/account',
            name: 'account',
            component: Account,
            meta: {
                requiresAuth: true,
                title: 'Profile'
            }
        },
        {
            path: '/history',
            name: 'history',
            component: History,
            meta: {
                requiresAuth: true,
                title: 'History'
            }
        },
        {
            path: '/users',
            component: IndexUsers,
            children: [
                {
                    path: '',
                    name: 'users.data',
                    component: DataUsers,
                    meta: {
                        requiresAuth: true,
                        title: 'Users Management'
                    }
                }
            ]
        },
        {
            path: '/roles',
            component: IndexRoles,
            children: [
                {
                    path: '',
                    name: 'roles.data',
                    component: DataRoles,
                    meta: {
                        requiresAuth: true,
                        title: 'Roles Management'
                    }
                }
            ]
        },
        {
            path: '/menus',
            component: IndexMenu,
            children: [
                {
                    path: '',
                    name: 'menus.data',
                    component: DataMenu,
                    meta: {
                        requiresAuth: true,
                        title: 'Menu Management'
                    }
                }
            ]
        },
        {
            path: '/promotion',
            component: IndexPromotion,
            children: [
                {
                    path: '',
                    name: 'promotion.data',
                    component: DataPromotion,
                    meta: {
                        requiresAuth: true,
                        title: 'Promotions Management'
                    }
                }
            ]
        },
        {
            path: '/permissions',
            component: IndexPermission,
            children: [
                {
                    path: '',
                    name: 'permissions.data',
                    component: DataPermission,
                    meta: {
                        requiresAuth: true,
                        title: 'Permissions Management'
                    }
                }
            ]
        },
        {
            path: '/shipto',
            component: IndexShipto,
            children: [
                {
                    path: '',
                    name: 'shipto.data',
                    component: DataShipto,
                    meta: {
                        requiresAuth: true,
                        title: 'Ship To Management'
                    }
                },
                {
                    path: 'recyclebin',
                    name: 'shipto.recyclebin',
                    component: DataRecycleBin,
                    meta: {
                        requiresAuth: true,
                        title: 'Ship To Recycle Bin'
                    }
                }
            ]
        },
        {
            path: '/orderlist',
            component: IndexOrder,
            children: [
                {
                    path: '',
                    name: 'orderlist.data',
                    component: DataOrder,
                    meta: {
                        requiresAuth: true,
                        title: 'Orders Management'
                    }
                },
                {
                    path: 'detail/:initial/:id',
                    name: 'orderlist.detail',
                    component: DataDetailOrder,
                    meta: {
                        requiresAuth: true,
                        title: 'Orders Detail'
                    }
                }
            ]
        },
        {
            path: '/quartals',
            component: IndexQuartalsBonusDealers,
            children: [
                {
                    path: '',
                    name: 'quartals.data',
                    component: DataQuartalsBonusDealers,
                    meta: {
                        requiresAuth: true,
                        title: 'Bonus Dealers Quartals'
                    }
                },
                {
                    path: '/detail/:dealer/:quartals/:years',
                    name: 'quartals.details',
                    component: DetailQuartalsBonusDealers,
                    meta: {
                        requiresAuth: true,
                        title: 'Bonus Dealers Quartals Details'
                    }
                },
                {
                    path: '/edit/:dealer/:quartals/:years',
                    name: 'quartals.edit',
                    component: EditQuartalsBonusDealers,
                    meta: {
                        requiresAuth: true,
                        title: 'Bonus Dealers Quartals Edit & Update'
                    }
                }
            ]
        },
        {
            path: '/semesters',
            component: IndexSemestersBonusDealers,
            children: [
                {
                    path: '',
                    name: 'semesters.data',
                    component: DataSemestersBonusDealers,
                    meta: {
                        requiresAuth: true,
                        title: 'Bonus Dealers Semesters'
                    }
                }
            ]
        },
        {
            path: '/report-bonus-dealer',
            component: IndexReportBonusDealers,
            children: [
                {
                    path: '',
                    name: 'reportbonusdealer.data',
                    component: DataReportBonusDealers,
                    meta: {
                        requiresAuth: true,
                        title: 'Bonus Dealers Report'
                    }
                }
            ]
        },
        {
            path: '/notification-b-d',
            component: IndexNotificationBonusDealers,
            children: [
                {
                    path: '',
                    name: 'notification.data',
                    component: DataNotificationBonusDealers,
                    meta: {
                        requiresAuth: true,
                        title: 'Bonus Dealers Notifications'
                    }
                }
            ]
        },
    ]
});
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        store.commit('CLEAR_ERRORS')
        let auth = store.getters.isAuth
        if (!auth) {
            next({ name: 'auth.login' })
        } else {
            next()
        }
    } else {
        next()
    }
})
export default router
