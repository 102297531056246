<template>
    <v-snackbar
      v-model="snackbar.snackbar_show"
      absolute
      centered
      top
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="indigo"
          text
          v-bind="attrs"
          @click="snackbar_hide"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState(['snackbar']),
        alertbanner: {
            get(){
                return this.$store.state.snackbar
            },
            set(v){
                return v
            } 
        }
    },
    methods: {
        snackbar_hide(){
            this.$store.commit('CLEAR_SNACKBAR')
        }
    }
}
</script>