var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersDetailsFO,"items":_vm.dessertsDetailsFO,"options":_vm.optionsDetailsFO,"server-items-length":_vm.totalDessertsDetailsFO,"loading":_vm.loadingTableDetailsFO,"single-expand":_vm.singleExpandDetailsFO,"expanded":_vm.expandedDetailsFO,"item-key":"id","show-expand":""},on:{"update:options":function($event){_vm.optionsDetailsFO=$event},"update:expanded":function($event){_vm.expandedDetailsFO=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.$router.go(-1)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-keyboard-backspace")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("Back pages")])]),_vm._v(" "),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.searchDetailsFO),callback:function ($$v) {_vm.searchDetailsFO=$$v},expression:"searchDetailsFO"}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-switch',{staticClass:"mt-2",attrs:{"label":"Single expand"},model:{value:(_vm.singleExpandDetailsFO),callback:function ($$v) {_vm.singleExpandDetailsFO=$$v},expression:"singleExpandDetailsFO"}})],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('p',{staticClass:"font-weight-black"},[_vm._v("SHIP TO : "+_vm._s(item.shifto))]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('p',{staticClass:"font-weight-black"},[_vm._v("\n                  QUARTAL : "+_vm._s(item.periode.substring(5, 7))+"\n                ")])],1)]},proxy:true},{key:"default",fn:function(){return [_c('tr',[_c('th',{staticClass:"text-left",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("DESC")]),_vm._v(" "),_c('td',{class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v(_vm._s(item.desc))]),_vm._v(" "),_c('th',{staticClass:"text-left",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("AMOUNT 1")]),_vm._v(" "),_c('td',{class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n                  "+_vm._s(item.amount_1 === null
                      ? "null"
                      : "Rp. " +
                        parseFloat(item.amount_1)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n                ")]),_vm._v(" "),_c('th',{staticClass:"text-left",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("AMOUNT 2")]),_vm._v(" "),_c('td',{class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n                  "+_vm._s(item.amount_2 === null
                      ? "null"
                      : "Rp. " +
                        parseFloat(item.amount_2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n                ")]),_vm._v(" "),_c('th',{staticClass:"text-left",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("AMOUNT 3")]),_vm._v(" "),_c('td',{class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n                  "+_vm._s(item.amount_3 === null
                      ? "null"
                      : "Rp. " +
                        parseFloat(item.amount_3)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n                ")])]),_vm._v(" "),_c('tr',[_c('th',{staticClass:"text-left",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("ITEM ID")]),_vm._v(" "),_c('td',{class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v(_vm._s(item.item_id))]),_vm._v(" "),_c('th',{staticClass:"text-left",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("PRICE 1")]),_vm._v(" "),_c('td',{class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n                  "+_vm._s(item.price_1 === null
                      ? "null"
                      : "Rp. " +
                        parseFloat(item.price_1)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n                ")]),_vm._v(" "),_c('th',{staticClass:"text-left",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("PRICE 2")]),_vm._v(" "),_c('td',[_vm._v("\n                  "+_vm._s(item.price_2 === null
                      ? "null"
                      : "Rp. " +
                        parseFloat(item.price_2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n                ")]),_vm._v(" "),_c('th',{staticClass:"text-left",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("PRICE 3")]),_vm._v(" "),_c('td',{class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n                  "+_vm._s(item.price_3 === null
                      ? "null"
                      : "Rp. " +
                        parseFloat(item.price_3)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n                ")])]),_vm._v(" "),_c('tr',[_c('th',{staticClass:"text-left",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("CATEGORY")]),_vm._v(" "),_c('td',{class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v(_vm._s(item.kategori))]),_vm._v(" "),_c('th',{staticClass:"text-left",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("DISC 1")]),_vm._v(" "),_c('td',{class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n                  "+_vm._s(item.disc_1 === null
                      ? "null"
                      : parseFloat(item.disc_1) + "%")+"\n                ")]),_vm._v(" "),_c('th',{staticClass:"text-left",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("DISC 2")]),_vm._v(" "),_c('td',{class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n                  "+_vm._s(item.disc_2 === null
                      ? "null"
                      : parseFloat(item.disc_2) + "%")+"\n                ")]),_vm._v(" "),_c('th',{staticClass:"text-left",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("DISC 3")]),_vm._v(" "),_c('td',{class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n                    "+_vm._s(item.disc_3 === null
                        ? "null"
                        : parseFloat(item.disc_3) + "%")+"\n                ")])])]},proxy:true}],null,true)})],1)],1)]}},{key:"item.item_id",fn:function(ref){
                        var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v("\n        "+_vm._s(item.item_id)+"\n      ")])]}},{key:"item.desc",fn:function(ref){
                        var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v("\n        "+_vm._s(item.desc)+"\n      ")])]}},{key:"item.box_qty",fn:function(ref){
                        var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v("\n        "+_vm._s(item.box_qty)+"\n      ")])]}},{key:"item.mtp_qty",fn:function(ref){
                        var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v("\n        "+_vm._s(item.mtp_qty)+"\n      ")])]}},{key:"item.qty_1",fn:function(ref){
                        var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v("\n        "+_vm._s(item.qty_1)+"\n      ")])]}},{key:"item.qty_2",fn:function(ref){
                        var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v("\n        "+_vm._s(item.qty_2)+"\n      ")])]}},{key:"item.qty_3",fn:function(ref){
                        var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v("\n        "+_vm._s(item.qty_3)+"\n      ")])]}},{key:"item.total_qty",fn:function(ref){
                        var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v("\n        "+_vm._s(item.total_qty)+"\n      ")])]}},{key:"item.total_amount",fn:function(ref){
                        var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v("\n        Rp.\n        "+_vm._s(parseFloat(item.total_amount)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n      ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }