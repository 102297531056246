import $axios from '../../core/api.js'
const state = () => ({
    desserts: [],
})
const mutations = {
    SET_DESSERTS(state, payload) {
        state.desserts = payload
    },
}
const actions = {
    Index({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post(`/bonus-dealer/report`, payload)
                .then((response) => {
                    if (response.status === 200 && response.data.status === "Token is Expired") {
                        setTimeout(() => {
                            localStorage.setItem('token', null)
                            commit('SET_TOKEN', null, { root: true })
                        }, 1000)
                        resolve(401)
                    } else {
                        commit('SET_DESSERTS', response.data)
                        resolve(response.status)
                    }
                })
        })
    },
    printpdf({ commit }, payload) {
        return new Promise((resolve) => {
            $axios.post(`/bonus-dealer/report/pdf`, payload, {
                responseType: 'blob'
            })
                .then((response) => {
                    if (response.status === 200 && response.data.status === "Token is Expired") {
                        setTimeout(() => {
                            localStorage.setItem('token', null)
                            commit('SET_TOKEN', null, { root: true })
                        }, 1000)
                        resolve(401)
                    } else {
                        resolve(response)
                    }
                })
                .catch(function (error) {
                    resolve(error.response)
                });
        })
    },
}
export default {
    namespaced: true,
    state,
    actions,
    mutations
}