<template>
  <div>
    <v-data-table
      :headers="headersDetailsFO"
      :items="dessertsDetailsFO"
      :options.sync="optionsDetailsFO"
      :server-items-length="totalDessertsDetailsFO"
      :loading="loadingTableDetailsFO"
      :single-expand="singleExpandDetailsFO"
      :expanded.sync="expandedDetailsFO"
      item-key="id"
      show-expand
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2"
                fab
                x-small
                v-bind="attrs"
                v-on="on"
                @click="$router.go(-1)"
              >
                <v-icon>mdi-keyboard-backspace</v-icon>
              </v-btn>
            </template>
            <span>Back pages</span>
          </v-tooltip>
          <v-text-field
            v-model="searchDetailsFO"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-switch
            v-model="singleExpandDetailsFO"
            label="Single expand"
            class="mt-2"
          ></v-switch>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container>
            <v-simple-table dense>
              <template v-slot:top>
                <v-toolbar flat>
                  <p class="font-weight-black">SHIP TO : {{ item.shifto }}</p>
                  <v-spacer></v-spacer>
                  <p class="font-weight-black">
                    QUARTAL : {{ item.periode.substring(5, 7) }}
                  </p>
                </v-toolbar>
              </template>
              <template v-slot:default>
                <tr>
                  <th class="text-left" v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">DESC</th>
                  <td v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">{{ item.desc }}</td>
                  <th class="text-left" v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">AMOUNT 1</th>
                  <td v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">
                    {{
                      item.amount_1 === null
                        ? "null"
                        : "Rp. " +
                          parseFloat(item.amount_1)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}
                  </td>
                  <th class="text-left" v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">AMOUNT 2</th>
                  <td v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">
                    {{
                      item.amount_2 === null
                        ? "null"
                        : "Rp. " +
                          parseFloat(item.amount_2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}
                  </td>
                  <th class="text-left" v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">AMOUNT 3</th>
                  <td v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">
                    {{
                      item.amount_3 === null
                        ? "null"
                        : "Rp. " +
                          parseFloat(item.amount_3)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="text-left" v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">ITEM ID</th>
                  <td v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">{{ item.item_id }}</td>
                  <th class="text-left" v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">PRICE 1</th>
                  <td v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">
                    {{
                      item.price_1 === null
                        ? "null"
                        : "Rp. " +
                          parseFloat(item.price_1)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}
                  </td>
                  <th class="text-left" v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">PRICE 2</th>
                  <td>
                    {{
                      item.price_2 === null
                        ? "null"
                        : "Rp. " +
                          parseFloat(item.price_2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}
                  </td>
                  <th class="text-left" v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">PRICE 3</th>
                  <td v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">
                    {{
                      item.price_3 === null
                        ? "null"
                        : "Rp. " +
                          parseFloat(item.price_3)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="text-left" v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">CATEGORY</th>
                  <td v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">{{ item.kategori }}</td>
                  <th class="text-left" v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">DISC 1</th>
                  <td v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">
                    {{
                      item.disc_1 === null
                        ? "null"
                        : parseFloat(item.disc_1) + "%"
                    }}
                  </td>
                  <th class="text-left" v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">DISC 2</th>
                  <td v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">
                    {{
                      item.disc_2 === null
                        ? "null"
                        : parseFloat(item.disc_2) + "%"
                    }}
                  </td>
                  <th class="text-left" v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">DISC 3</th>
                  <td v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark',]">
                      {{
                        item.disc_3 === null
                          ? "null"
                          : parseFloat(item.disc_3) + "%"
                      }}
                  </td>
                </tr>
              </template>
            </v-simple-table>
          </v-container>
        </td>
      </template>
      <template v-slot:[`item.item_id`]="{ item }">
        <p
          v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']"
          class="mt-3"
        >
          {{ item.item_id }}
        </p>
      </template>
      <template v-slot:[`item.desc`]="{ item }">
        <p
          v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']"
          class="mt-3"
        >
          {{ item.desc }}
        </p>
      </template>
      <template v-slot:[`item.box_qty`]="{ item }">
        <p
          v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']"
          class="mt-3"
        >
          {{ item.box_qty }}
        </p>
      </template>
      <template v-slot:[`item.mtp_qty`]="{ item }">
        <p
          v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']"
          class="mt-3"
        >
          {{ item.mtp_qty }}
        </p>
      </template>
      <template v-slot:[`item.qty_1`]="{ item }">
        <p
          v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']"
          class="mt-3"
        >
          {{ item.qty_1 }}
        </p>
      </template>
      <template v-slot:[`item.qty_2`]="{ item }">
        <p
          v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']"
          class="mt-3"
        >
          {{ item.qty_2 }}
        </p>
      </template>
      <template v-slot:[`item.qty_3`]="{ item }">
        <p
          v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']"
          class="mt-3"
        >
          {{ item.qty_3 }}
        </p>
      </template>
      <template v-slot:[`item.total_qty`]="{ item }">
        <p
          v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']"
          class="mt-3"
        >
          {{ item.total_qty }}
        </p>
      </template>
      <template v-slot:[`item.total_amount`]="{ item }">
        <p
          v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']"
          class="mt-3"
        >
          Rp.
          {{
            parseFloat(item.total_amount)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }}
        </p>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState(["loading"]),
    ...mapState("listfo", {
      searchDetailsFO: (state) => state.searchDetailsFO,
      loadingTableDetailsFO: (state) => state.loadingTableDetailsFO,
      totalDessertsDetailsFO: (state) => state.totalDessertsDetailsFO,
      dessertsDetailsFO: (state) => state.dessertsDetailsFO,
      optionsDetailsFO: (state) => state.optionsDetailsFO,
      headersDetailsFO: (state) => state.headersDetailsFO,
      expandedDetailsFO: (state) => state.expandedDetailsFO,
      singleExpandDetailsFO: (state) => state.singleExpandDetailsFO,
    }),
    expandedDetailsFO: {
      get: function () {
        return this.$store.state.listfo.expandedDetailsFO;
      },
      set: function (value) {
        this.$store.commit("listfo/EXPANDED_DETAILS_FO", value);
      },
    },
    singleExpandDetailsFO: {
      get: function () {
        return this.$store.state.listfo.singleExpandDetailsFO;
      },
      set: function (value) {
        this.$store.commit("listfo/SINGLEXPAND_DETAILS_FO", value);
      },
    },
    optionsDetailsFO: {
      get: function () {
        return this.$store.state.listfo.optionsDetailsFO;
      },
      set: function (value) {
        this.$store.commit("listfo/SET_OPTIONS_DETAIL_FO", value);
      },
    },
    searchDetailsFO: {
      get: function () {
        return this.$store.state.listfo.searchDetailsFO;
      },
      set: function (value) {
        this.$store.commit("listfo/SET_SEARCH_DETAIL_FO", value);
      },
    },
  },
  watch: {
    optionsDetailsFO: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    searchDetailsFO() {
      this.getDataFromApi();
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    ...mapActions("listfo", ["IndexDetailFO"]),
    getDataFromApi() {
      let id = this.$route.params.id;
      this.IndexDetailFO(id);
    },
  },
};
</script>