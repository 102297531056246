import $axios from '../../core/api.js'

const state = () => ({
  // STATE TABLE STARTED
  totalDesserts: 0,
  desserts: [],
  search: '',
  options: {},
  headers: [
    { text: 'ID', value: 'ID' },
    { text: 'AREA ID', value: 'DATAAREAID' },
    {
      text: 'ID',
      align: 'start',
      sortable: false,
      value: 'SALESDISTRICTID',
    },
    { text: 'GROUP', value: 'CUSTGROUP' },
    { text: 'NO', value: 'ACCOUNTNUM' },
    { text: '', value: 'data-table-expand' },
  ],
  // STATE TABLE ENDED
  // STATE FORM STARTED
  dialog: {
    show: false,
    param: 0
  },
  form: {
    name: '',
    type: '',
    extend: '',
    icon: '',
    link: '',
    authorization: ''
  },
  authorization: [],
  extend: [],
  // STATE FORM ENDED
})

const mutations = {
  // MUTATION TABLE STARTED
  SET_DATATABLES(state, payload) {
    state.totalDesserts = payload.totalDesserts,
      state.desserts = payload.desserts
  },
  SET_OPTIONS(state, payload) {
    state.options = payload
  },
  SET_SEARCH(state, payload) {
    state.search = payload
  },
  SET_DIALOG(state, payload) {
    state.dialog = {
      show: payload.show,
      id: payload.id
    }
  },
  SET_FORM(state, payload) {
    state.form = {
      name: payload.name,
      type: payload.type,
      extend: payload.extend,
      icon: payload.icon,
      link: payload.link,
      authorization: payload.authorization
    }
  },
  CLEAR_FORM(state, payload) {
    state.form = {
      name: '',
      type: '',
      extend: '',
      icon: '',
      link: '',
      authorization: ''
    }
  },
  SET_EXTEND(state, payload) {
    state.extend = payload
  },
  SET_AUTHORIZATION(state, payload) {
    state.authorization = payload
  },
}

const actions = {
  Index({ commit, state }) {
    commit('SET_LOADING', true, { root: true })
    return new Promise((resolve) => {
      const { page, itemsPerPage } = state.options;
      $axios
        .get(`/shipmaster?initials=core&page=${page}&perpage=${itemsPerPage}&search=${state.search}`)
        .then(function (response) {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            function dealer(e) {
              return e.CUSTGROUP === "Dealer";
            }
            const setdata = {
              desserts: response.data.data.data,
              totalDesserts: parseInt(response.data.data.total)
            };
            console.log(setdata);
            commit('SET_DATATABLES', setdata)
            commit('SET_LOADING', false, { root: true })
            resolve(response.status)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
