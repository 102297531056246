<template>
  <v-data-table
    dense
    v-model="selected"
    :single-select="select"
    item-key="id"
    show-select
    show-expand
    :expanded.sync="expanded"
    :headers="headers"
    :items="desserts"
    :search="search"
    :options.sync="options"
    :server-items-length="totalDesserts"
    :loading="loading"
  >
    <template v-slot:top>
      <v-toolbar flat class="rounded-xl">
        <v-toolbar-title>Masters</v-toolbar-title>
        <v-switch
          v-model="select"
          class="pa-3 mt-7"
          label="Select"
          color="primary"
        ></v-switch>
        <v-spacer />
        <v-switch
          v-model="singleExpand"
          label="Expand"
          class="pa-3 mt-7"
          color="primary"
        ></v-switch>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn class="mx-2" fab x-small v-if="selected.length > 0 && $can('shipmaster-delete')" @click="deleted()" color="error">
          <v-icon dark> mdi-delete </v-icon>
        </v-btn>
        <v-spacer />
        <app-alert />
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="dialogDelete = false"
                >Cancel</v-btn
              >
              <v-btn color="primary" text @click="deleteConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:[`item.area_id`]="{ item }">
      <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.area_id.toUpperCase() }}</p>
    </template>
    <template v-slot:[`item.sales_district_id`]="{ item }">
      <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.sales_district_id }}</p>
    </template>
    <template v-slot:[`item.custgroup`]="{ item }">
      <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.custgroup }}</p>
    </template>
    <template v-slot:[`item.accountnum`]="{ item }">
      <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.accountnum }}</p>
    </template>
    <template v-slot:[`item.aliases`]="{ item }">
      <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.aliases }}</p>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <div class="pa-3 mt-7">
          <v-text-field label="Name" disabled outlined dense v-model="item.name"/>
          <v-text-field 
          label="Aliases" 
          placeholder="Enter aliases..." 
          outlined dense 
          v-model="item.aliases"
          append-outer-icon="mdi-send"
          @click:append-outer="update({
                id: item.id,
                aliases: item.aliases
            })"
          />
        </div>
      </td>
    </template>
  </v-data-table>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    "app-alert": Alert,
  },
  data() {
    return {
      expanded: [],
      singleExpand: false,
      selected: [],
      select: false,
      dialogDelete: false,
    };
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState("shipto", {
      totalDesserts: (state) => state.totalDesserts,
      desserts: (state) => state.desserts,
      search: (state) => state.search,
      options: (state) => state.options,
      headers: (state) => state.headers,
    }),
    options: {
      get: function () {
        return this.$store.state.shipto.options;
      },
      set: function (value) {
        this.$store.commit("shipto/SET_OPTIONS", value);
      },
    },
    search: {
      get: function () {
        return this.$store.state.shipto.search;
      },
      set: function (value) {
        this.$store.commit("shipto/SET_SEARCH", value);
      },
    },
    loading: {
      get: function () {
        return this.$store.state.loading;
      },
      set: function (value) {
        this.$store.commit("SET_LOADING", value);
      },
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search() {
      this.getDataFromApi();
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    ...mapActions("shipto", [
      "Index",
      "updated",
      "delete",
    ]),
    ...mapActions("auth", ["logout"]),
    getDataFromApi() {
      this.Index().then((e) => {
        if (e != 200) {
          this.logout().then((r) => {
            this.$router.push({ name: "auth.login" });
          });
        }
      });
    },
    update(e) {
      this.$store.commit("shipto/SET_FORM", e);
      this.updated().then((res) => {
        console.log(res);
      });
    },
    deleted() {
      this.dialogDelete=true;
    },
    deleteConfirm() {
      this.delete(this.selected).then((e) => {
        if (e === 200) {
          this.dialogDelete = false;
        }
      });
    },
  },
};
</script>