<template>
    <v-form class="my-10" lazy-validation autocomplete="off">
        <v-text-field
            label="Enter email address.."
            v-model="form.email"
            type="email"
            rounded
            append-icon="mdi-email"
            autocomplete="off"
            required
            outlined
        />
        <v-text-field
            label="Enter password.."
            v-model="form.password"
            type="password"
            rounded
            append-icon="mdi-lock"
            autocomplete="off"
            required
            outlined
        />
        <v-row class="d-flex justify-space-between">
            <v-checkbox
            v-model="form.remember"
            :label="`Remember me: ${form.remember.toString()}`"
            />
            <v-btn
            class="mt-4"
            :loading="loading"
            @click="handleLogin"
            rounded
            color="primary"
            dark
            >
            Sign in
            </v-btn>
        </v-row>
    </v-form>
</template>
<script>
import { mapGetters,mapState,mapMutations,mapActions } from "vuex";
export default {
    created(){
        var getToken = location.href.match(/([^\/]*)\/*$/)[1];
        if (getToken != 'login') {
            this.login(getToken).then(() => {
                if (this.isAuth) {
                    this.$router.push({ name: 'home' })
                }
            })   
        }
    },
    computed:{
        ...mapGetters(['isAuth']),
        ...mapState([
            'loading',
        ]),
        ...mapState('auth', {
            form: state => state.formlogin
        })
    },
    methods:{
        ...mapActions('auth', ['submitLogin']),
        ...mapMutations(['CLEAR_ERRORS']),
        handleLogin(){
            this.submitLogin().then((e) => {
                if (this.isAuth) {
                    this.$router.push({ name: 'home' })
                }
            })
        }
    }
}
</script>