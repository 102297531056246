<template>
    <div>
        <v-card class="mx-auto">
            <v-toolbar>
                <v-select :items="itemsKuartal" label="Kuartal" item-text="latin" item-value="value" class="mt-8 mr-3"
                    v-model="form.quartal"></v-select>
                <v-divider vertical></v-divider>
                <v-select :items="itemsTahun" label="Tahun" class="mt-8 mr-3 ml-3" v-model="form.year"></v-select>
                <v-divider vertical></v-divider>
                <v-progress-circular indeterminate color="primary" class="mx-2" v-if="loading"></v-progress-circular>
                <v-btn class="mx-2 mt-1" fab dark x-small color="primary" @click="submit" v-else>
                    <v-icon dark>
                        mdi-send
                    </v-icon>
                </v-btn>
            </v-toolbar>
        
            <v-card-text>
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Name
                                </th>
                                <th class="text-left">
                                    Email
                                </th>
                                <th class="text-left">
                                    Quartal
                                </th>
                                <th class="text-left">
                                    Year
                                </th>
                                <th class="text-left">
                                    Date Confirm
                                </th>
                                <th class="text-left">
                                    Status Confirm
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in desserts" :key="item.name">
                                <td v-bind:class="[
    $vuetify.theme.dark ? 'text-light' : 'text-dark',
]">{{ item.name }}</td>
                                <td v-bind:class="[
    $vuetify.theme.dark ? 'text-light' : 'text-dark',
]">{{ item.email }}</td>
                                <td v-bind:class="[
    $vuetify.theme.dark ? 'text-light' : 'text-dark',
]">{{ item.quartal }}</td>
                                <td v-bind:class="[
    $vuetify.theme.dark ? 'text-light' : 'text-dark',
]">{{ item.year }}</td>
                                <td v-bind:class="[
    $vuetify.theme.dark ? 'text-light' : 'text-dark',
]">{{ moment(item.confirm_at).format('YYYY-MM-DD HH:mm:ss') }}</td>
                                <td>
                                    <v-icon :color="(item.konfirm === 'y') ? 'success' : 'error'">{{ (item.konfirm === 'y') ?
        'mdi-check-all' : 'mdi-close'
}}</v-icon>
                                    <v-btn small outlined v-if="item.konfirm === 'y'" @click="setdialog(item.id, form.quartal, form.year)">
                                        <v-icon dark>
                                            mdi-file-pdf-box
                                        </v-icon>
                                        print
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
        <!-- dialog pdf::START -->
        <v-dialog v-model="dialog" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5"> Export PDF file </v-card-title>
                <v-card-text>
                    <v-text-field label="Nomor Surat" v-model="form_pdf.print_numbers"
                        :error-messages="error_form_pdf.print_numbers" required
                        hint="example: 574/CSA-IPD/XI/21"></v-text-field>
                    <v-text-field label="Atas nama pemberi hormat (Responsible)" v-model="form_pdf.name_responsible"
                        :error-messages="error_form_pdf.name_responsible" required></v-text-field>
                    <v-text-field label="Atas nama penyetujui (Customer)" v-model="form_pdf.name_customers"
                        :error-messages="error_form_pdf.name_customers" required></v-text-field>
                    <v-textarea v-model="form_pdf.address_company_customers" label="Alamat Lengkap"
                        value="alamat lengkap perusahaan (custemers)"
                        :error-messages="error_form_pdf.address_company_customers"></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="pdf()" :loading="loadpdf"> print </v-btn>
                    <v-btn color="error" text @click="dialog = false"> close </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- dialog pdf::PDF -->
    </div>
</template>
<script>
import moment from 'moment'
import { mapActions, mapState } from "vuex";
export default {
    data: () => ({
        itemsKuartal: [
            { no: 1, latin: 'Januari-Maret', value: '01-03' },
            { no: 2, latin: 'April-Juni', value: '04-06' },
            { no: 3, latin: 'Juli-September', value: '07-09' },
            { no: 4, latin: 'Oktober-Desember', value: '10-12' },
        ],
        itemsTahun: [],
        form: {
            quartal: '',
            year: ''
        },
        formparams: {
            dealers: '',
            quartals: '',
            year: '',
        },
        loading: false,
        dialog: false,
        loadpdf: false,
    }),
    computed: {
        ...mapState("notif_bonus_dealer", {
            desserts: (state) => state.desserts,
        }),
        ...mapState("quartals", {
            form_pdf: (state) => state.form_pdf,
            error_form_pdf: (state) => state.error_form_pdf,
        }),
        error_form_pdf: {
            get: function () {
                return this.$store.state.quartals.error_form_pdf;
            },
            set: function (value) {
                this.$store.commit("quartals/SET_ERROR_FORM_PDF", value);
            },
        },
    },
    mounted() {
        this.setYears()
    },
    methods: {
        ...mapActions("notif_bonus_dealer", ["Index"]),
        ...mapActions("quartals", ["printpdf"]),
        moment(date) { return moment(date) },
        setdialog(dealer, quartal, year){
            this.dialog=true
            this.formparams ={
                dealers: dealer,
                quartals: quartal,
                year: year,
            }
        },
        setYears() {
            var max = new Date().getFullYear();
            var min = max - 9;
            var years = this.itemsTahun;
            for (var i = max; i >= min; i--) {
                years.push(i);
            }
        },
        submit() {
            this.loading = true;
            this.Index(this.form).then(() => {
                this.loading = false;
            })
        },
        pdf() {
            this.loadpdf = true
            this.printpdf(this.formparams).then((res) => {
                console.log(res.data);
                this.loadpdf = false
                this.dialog = false;
                const file = new Blob([res.data], { type: "application/pdf" });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            });
        },
    }
}
</script>