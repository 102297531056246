var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center"},[_c('p',[_vm._v("PT. CAPELLA PATRIA UTAMA-PKB")])]),_vm._v(" "),(_vm.loading)?_c('v-card-text',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"purple","indeterminate":""}})],1):_vm._e(),_vm._v(" "),_c('v-card-text',[_c('v-expansion-panels',_vm._l((_vm.desserts_edit),function(item,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',{domProps:{"textContent":_vm._s(item.no_order)}}),_vm._v(" "),_c('v-expansion-panel-content',[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("PRODUCT GROUP")]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v("TARGET")]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v("OKTOBER")]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v("NOVEMBER")]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v("DESEMBER")]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v("TOTAL FO Q4 2020")]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v("%TGT-ACH%")]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v("%INCENT")]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v("INCENTIVE")])])]),_vm._v(" "),_c('tbody',_vm._l((item.data),function(items){return _c('tr',{key:items.kelompok_produk,class:_vm.getColor(items.kelompok_produk, _vm.$vuetify.theme.dark)},[_c('td',{class:[
                        _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n                      "+_vm._s(items.kelompok_produk)+"\n                    ")]),_vm._v(" "),_c('td',{class:[
                        _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n                      "+_vm._s(parseFloat(items.target)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n                    ")]),_vm._v(" "),_c('td',{class:[
                        _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_c('v-text-field',{attrs:{"dense":"","type":"number"},on:{"change":function($event){return _vm.changeValue({
                        'id':items.qty_1.id, 
                        'qty':items.qty_1.qty,
                        'field':'qty_1',
                        'id_sfo':items.id_sfo
                        })}},model:{value:(items.qty_1.qty),callback:function ($$v) {_vm.$set(items.qty_1, "qty", $$v)},expression:"items.qty_1.qty"}},[_c('v-icon',{attrs:{"slot":"append","color":"red","small":""},slot:"append"},[_vm._v("\n                          mdi-plus\n                        ")]),_vm._v(" "),_c('v-icon',{attrs:{"slot":"append","color":"green","small":""},slot:"append"},[_vm._v("\n                          mdi-minus\n                        ")])],1)],1),_vm._v(" "),_c('td',{class:[
                        _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_c('v-text-field',{attrs:{"dense":"","type":"number"},on:{"change":function($event){return _vm.changeValue({
                        'id':items.qty_2.id, 
                        'qty':items.qty_2.qty,
                        'field':'qty_2',
                        'id_sfo':items.id_sfo
                        })}},model:{value:(items.qty_2.qty),callback:function ($$v) {_vm.$set(items.qty_2, "qty", $$v)},expression:"items.qty_2.qty"}},[_c('v-icon',{attrs:{"slot":"append","color":"red","small":""},slot:"append"},[_vm._v("\n                          mdi-plus\n                        ")]),_vm._v(" "),_c('v-icon',{attrs:{"slot":"append","color":"green","small":""},slot:"append"},[_vm._v("\n                          mdi-minus\n                        ")])],1)],1),_vm._v(" "),_c('td',{class:[
                        _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_c('v-text-field',{attrs:{"dense":"","type":"number"},on:{"change":function($event){return _vm.changeValue({
                        'id':items.qty_3.id, 
                        'qty':items.qty_3.qty,
                        'field':'qty_3',
                        'id_sfo':items.id_sfo
                        })}},model:{value:(items.qty_3.qty),callback:function ($$v) {_vm.$set(items.qty_3, "qty", $$v)},expression:"items.qty_3.qty"}},[_c('v-icon',{attrs:{"slot":"append","color":"red","small":""},slot:"append"},[_vm._v("\n                          mdi-plus\n                        ")]),_vm._v(" "),_c('v-icon',{attrs:{"slot":"append","color":"green","small":""},slot:"append"},[_vm._v("\n                          mdi-minus\n                        ")])],1)],1),_vm._v(" "),_c('td',{class:[
                        _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n                      "+_vm._s(parseFloat(items.fo)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n                    ")]),_vm._v(" "),_c('td',{class:[
                        _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n                      "+_vm._s(items.tgt_ach)+" %\n                    ")]),_vm._v(" "),_c('td',{class:[
                        _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n                      "+_vm._s(items.incent)+" %\n                    ")]),_vm._v(" "),_c('td',{class:[
                        _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n                      "+_vm._s(parseFloat(items.incentive)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n                    ")])])}),0)]},proxy:true}],null,true)})],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }