import $axios from '../core/api.js'

const state = () => ({
    formlogin: {
        email: '',
        password: '',
        remember: false
    },
    formregist: {
        name: '',
        email: '',
        password: '',
        confirm_password: '',
        remember: false
    },
    formaccount: {
        name: '',
        email: '',
        password: '',
        confirm_password: ''
    },
    menu: [],
    authenticated: []
})

const mutations = {
    SET_MENU(state, payload) {
        state.menu = payload
    },
    ASSIGN_USER_AUTH(state, payload) {
        state.authenticated = payload
    },
    ASSIGN_FORM(state, payload) {
        state.formlogin = {
            email: payload.email,
            password: payload.password,
            remember: payload.remember
        }
    },
    CLEAR_FORM(state) {
        state.formlogin = {
            email: '',
            password: '',
            remember: false
        }
    },
    ASSIGN_REGIST(state, payload) {
        state.formlogin = {
            name: payload.name,
            email: payload.email,
            password: payload.password,
            confirm_password: payload.remember,
            remember: payload.remember,
        }
    },
    ASSIGN_ACCOUNT(state, payload) {
        state.formaccount = {
            name: payload.name,
            email: payload.email,
            password: payload.password,
            confirm_password: payload.remember
        }
    },
    CLEAR_REGIST(state) {
        state.formlogin = {
            name: '',
            email: '',
            confirm_password: '',
            password: '',
            remember: false
        }
    }
}

const actions = {
    getHistory({ commit },payload) {
        commit('SET_LOADING', true, { root: true })
        return new Promise((resolve) => {
            $axios
                .get(`/history?page=${payload}`)
                .then(function (response) {
                    if (response.status === 200 && response.data.status === "Token is Expired") {
                        setTimeout(() => {
                            localStorage.setItem('token', null)
                            commit('SET_TOKEN', null, { root: true })
                        }, 1000)
                        resolve(401)
                    } else {
                        commit('SET_LOADING', false, { root: true })
                        resolve(response.data)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    },
    submitLogin({ commit, state }) {
        commit('SET_LOADING', true, { root: true })
        localStorage.setItem('token', null)
        commit('SET_TOKEN', null, { root: true })
        return new Promise((resolve) => {
            $axios.post('/login', state.formlogin)
                .then((response) => {
                    if (response.status === 200 && response.data.status === "Token is Expired") {
                        setTimeout(() => {
                            localStorage.setItem('token', null)
                            commit('SET_TOKEN', null, { root: true })
                        }, 1000)
                        resolve(401)
                    } else {
                        commit('SET_ALERTBANNER', response.data, { root: true })
                        setTimeout(() => {
                            commit('CLEAR_ALERTBANNER', response.data, { root: true })
                        }, 4000)
                        if (response.data.status != 'error') {
                            localStorage.setItem('token', response.data.token)
                            commit('SET_TOKEN', response.data.token, { root: true })
                        }
                        resolve(response.data)
                        commit('CLEAR_FORM')
                        commit('SET_LOADING', false, { root: true })
                    }
                })
                .catch((error) => {
                    commit('SET_ERRORS', error.response.data.errors, { root: true })
                })
        })
    },

    getMenu({ commit }) {
        return new Promise((resolve) => {
            $axios.get(`/get-menu`)
                .then((response) => {
                    if (response.status === 200 && response.data.status === "Token is Expired") {
                        setTimeout(() => {
                            localStorage.setItem('token', null)
                            commit('SET_TOKEN', null, { root: true })
                        }, 1000)
                        resolve(401)
                    } else {
                        if (response.status === 200 && response.data.status === "Token is Expired") {
                            let setSnackbar = { message: response.data.status };
                            commit('SET_SNACKBAR', setSnackbar, { root: true })
                            setTimeout(() => {
                                localStorage.setItem('token', null)
                                commit('SET_TOKEN', null, { root: true })
                            }, 1000)
                        } else {
                            commit('SET_MENU', response.data)
                        }
                        resolve(response.status)
                    }
                })
                .catch((error) => {
                    if (error.response.status === 403) {
                        let setDialog = { message: error.message };
                        commit('SET_DIALOG_MEDIUM', setDialog, { root: true })
                    }
                    let setSnackbar = { message: error.message };
                    commit('SET_SNACKBAR', setSnackbar, { root: true })
                })
        })
    },
    getUserLogin({ commit }) {
        return new Promise((resolve, reject) => {
        $axios.get(`auth-user`)
            .then((response) => {
                if (response.status === 200 && response.data.status === "Token is Expired") {
                    setTimeout(() => {
                        localStorage.setItem('token', null)
                        commit('SET_TOKEN', null, { root: true })
                    }, 1000)
                    resolve(401)
                } else {
                    if (response.status === 200 && response.data.status === "Token is Expired") {
                        let setSnackbar = { message: response.data.status };
                        commit('SET_SNACKBAR', setSnackbar, { root: true })
                        setTimeout(() => {
                            localStorage.setItem('token', null)
                            commit('SET_TOKEN', null, { root: true })
                        }, 1000)
                    } else {
                        commit('ASSIGN_USER_AUTH', response.data.data)
                        commit('ASSIGN_ACCOUNT', response.data.data)
                    }
                    resolve(response.status)
                }
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    let setDialog = { message: error.message };
                    commit('SET_DIALOG_MEDIUM', setDialog, { root: true })
                }
                let setSnackbar = { message: error.message };
                commit('SET_SNACKBAR', setSnackbar, { root: true })
            })
        })
    },
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get(`logout`)
                .then((response) => {
                    if (response.status === 200 && response.data.status === "Token is Expired") {
                        setTimeout(() => {
                            localStorage.setItem('token', null)
                            commit('SET_TOKEN', null, { root: true })
                        }, 1000)
                        resolve(401)
                    } else {
                        if (response.status === 200 && response.data.status === "Token is Expired") {
                            let setSnackbar = { message: response.data.status };
                            commit('SET_SNACKBAR', setSnackbar, { root: true })
                            setTimeout(() => {
                                localStorage.setItem('token', null)
                                commit('SET_TOKEN', null, { root: true })
                            }, 1000)
                        } else {
                            localStorage.setItem('token', null)
                            commit('SET_TOKEN', null, { root: true })
                        }
                        resolve(response.status)
                    }
                })
                .catch((error) => {
                    if (error.response.status === 403) {
                        let setDialog = { message: error.message };
                        commit('SET_DIALOG_MEDIUM', setDialog, { root: true })
                    }
                    let setSnackbar = { message: error.message };
                    commit('SET_SNACKBAR', setSnackbar, { root: true })
                })
        })
    },
    update_account({ commit, state }) {
        return new Promise((resolve) => {
            $axios.post('/update-account', state.formaccount)
                .then((response) => {
                    if (response.status === 200 && response.data.status === "Token is Expired") {
                        setTimeout(() => {
                            localStorage.setItem('token', null)
                            commit('SET_TOKEN', null, { root: true })
                        }, 1000)
                        resolve(401)
                    } else {
                        commit('SET_ALERTBANNER', response.data, { root: true })
                        setTimeout(() => {
                            commit('CLEAR_ALERTBANNER', response.data, { root: true })
                        }, 4000)
                        resolve(response)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        commit('SET_ERRORS', error.response.data.errors, { root: true })
                        resolve(error.response.status)
                    }
                    let setSnackbar = { message: error.message };
                    commit('SET_SNACKBAR', setSnackbar, { root: true })
                })
        })
    },
    orderNotification({ commit }) {
        return new Promise((resolve) => {
            $axios.get(`get-count-notif`)
            .then((response) => {
                if (response.status === 200 && response.data.status === "Token is Expired") {
                    setTimeout(() => {
                        localStorage.setItem('token', null)
                        commit('SET_TOKEN', null, { root: true })
                    }, 1000)
                    resolve(401)
                } else {
                    resolve(response.data)
                    commit('SET_ORDER_NOTIF', response.data, { root: true })
                }
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
