import $axios from '../../core/api.js'

const state = () => ({
  dealers: [],
  desserts: [],
  desserts_edit: [],
  form_pdf: {
    print_numbers: '',
    name_responsible: '',
    name_customers: '',
    address_company_customers: '',
  },
  error_form_pdf: [],
})

const mutations = {
  SET_DEALERS(state, payload) {
    state.dealers = payload
  },
  SET_DESSERTS(state, payload) {
    state.desserts = payload
  },
  SET_DESSERTS_EDIT(state, payload) {
    state.desserts_edit = payload
  },
  SET_FORM_PDF(state, payload) {
    state.form_pdf = payload
  },
  SET_ERROR_FORM_PDF(state, payload) {
    state.error_form_pdf = payload
  },
}

const actions = {
  getDealers({ commit }) {
    return new Promise((resolve, reject) => {
      $axios.get(`/bonus-dealer/get-dealer`)
        .then((response) => {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            commit('SET_DEALERS', response.data)
            resolve(response.status)
          }
        })
    })
  },
  Index({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      $axios.get(`/bonus-dealer?dealers=${payload.dealers}&quartals=${payload.quartals}&year=${payload.year}`)
        .then((response) => {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            const arr = Array();
            const total = Array();
            response.data.INDOPART.forEach(el => {
              arr.push(el)
            });
            const sumTarget = response.data.INDOPART.reduce((accumulator, object) => {
              return accumulator + object.target;
            }, 0);
            const sumFO = response.data.INDOPART.reduce((accumulator, object) => {
              return accumulator + object.fo;
            }, 0);
            const sumIncentive = response.data.INDOPART.reduce((accumulator, object) => {
              return accumulator + object.incentive;
            }, 0);
            const sum_amount_first = response.data.INDOPART.reduce((accumulator, object) => {
              return accumulator + object.total_amount_first;
            }, 0);
            const sum_qty_first = response.data.INDOPART.reduce((accumulator, object) => {
              return accumulator + object.total_qty_first;
            }, 0);
            const sum_amount_second = response.data.INDOPART.reduce((accumulator, object) => {
              return accumulator + object.total_amount_second;
            }, 0);
            const sum_qty_second = response.data.INDOPART.reduce((accumulator, object) => {
              return accumulator + object.total_qty_second;
            }, 0);
            const sum_amount_thirth = response.data.INDOPART.reduce((accumulator, object) => {
              return accumulator + object.total_amount_thirth;
            }, 0);
            const sum_qty_thirth = response.data.INDOPART.reduce((accumulator, object) => {
              return accumulator + object.total_qty_thirth;
            }, 0);
            arr.push({
              product: 'INDOPARTS',
              kelompok_produk: 'INDOPARTS',
              target: sumTarget,
              total_amount_first: sum_amount_first,
              total_qty_first: sum_qty_first,
              total_amount_second: sum_amount_second,
              total_qty_second: sum_qty_second,
              total_amount_thirth: sum_amount_thirth,
              total_qty_thirth: sum_qty_thirth,
              fo: sumFO,
              tgt_ach: 0,
              incent: 0,
              incentive: sumIncentive,
            })
            total.push({
              product: 'TOTAL',
              kelompok_produk: 'TOTAL',
              target: sumTarget + response.data.INDOBATTERY[0].target + response.data.INDOTIRE[0].target,
              total_amount_first: sum_amount_first + response.data.INDOBATTERY[0].total_amount_first + response.data.INDOTIRE[0].total_amount_first,
              total_qty_first: sum_qty_first + response.data.INDOBATTERY[0].total_qty_first + response.data.INDOTIRE[0].total_qty_first,
              total_amount_second: sum_amount_second + response.data.INDOBATTERY[0].total_amount_second + response.data.INDOTIRE[0].total_amount_second,
              total_qty_second: sum_qty_second + response.data.INDOBATTERY[0].total_qty_second + response.data.INDOTIRE[0].total_qty_second,
              total_amount_thirth: sum_amount_thirth + response.data.INDOBATTERY[0].total_amount_thirth + response.data.INDOTIRE[0].total_amount_thirth,
              total_qty_thirth: sum_qty_thirth + response.data.INDOBATTERY[0].total_amount_thirth + response.data.INDOTIRE[0].total_qty_thirth,
              fo: sumFO + response.data.INDOBATTERY[0].fo + response.data.INDOTIRE[0].fo,
              tgt_ach: 0,
              incent: 0,
              incentive: sumIncentive + response.data.INDOBATTERY[0].incentive + response.data.INDOTIRE[0].incentive,
            })
            commit('SET_DESSERTS', {INDOPART: arr, INDOBATTERY: response.data.INDOBATTERY, INDOTIRE: response.data.INDOTIRE, TOTAL: total})
            resolve(response.status)
          }
        })
    })
  },
  Edit({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      $axios.get(`/bonus-dealer/${payload.dealers}/edit?quartals=${payload.quartals}&year=${payload.year}`)
        .then((response) => {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            const arr = Array();
            const total = Array();
            response.data.INDOPART.forEach(el => {
              arr.push(el)
            });
            const sumTarget = response.data.INDOPART.reduce((accumulator, object) => {
              return accumulator + object.target;
            }, 0);
            const sumFO = response.data.INDOPART.reduce((accumulator, object) => {
              return accumulator + object.fo;
            }, 0);
            const sumIncentive = response.data.INDOPART.reduce((accumulator, object) => {
              return accumulator + object.incentive;
            }, 0);
            const sum_amount_first = response.data.INDOPART.reduce((accumulator, object) => {
              return accumulator + object.total_amount_first;
            }, 0);
            const sum_qty_first = response.data.INDOPART.reduce((accumulator, object) => {
              return accumulator + object.total_qty_first;
            }, 0);
            const sum_amount_second = response.data.INDOPART.reduce((accumulator, object) => {
              return accumulator + object.total_amount_second;
            }, 0);
            const sum_qty_second = response.data.INDOPART.reduce((accumulator, object) => {
              return accumulator + object.total_qty_second;
            }, 0);
            const sum_amount_thirth = response.data.INDOPART.reduce((accumulator, object) => {
              return accumulator + object.total_amount_thirth;
            }, 0);
            const sum_qty_thirth = response.data.INDOPART.reduce((accumulator, object) => {
              return accumulator + object.total_qty_thirth;
            }, 0);
            arr.push({
              product: 'INDOPARTS',
              kelompok_produk: 'INDOPARTS',
              target: sumTarget,
              total_amount_first: sum_amount_first,
              total_qty_first: sum_qty_first,
              total_amount_second: sum_amount_second,
              total_qty_second: sum_qty_second,
              total_amount_thirth: sum_amount_thirth,
              total_qty_thirth: sum_qty_thirth,
              fo: sumFO,
              tgt_ach: 0,
              incent: 0,
              incentive: sumIncentive,
            })
            total.push({
              product: 'TOTAL',
              kelompok_produk: 'TOTAL',
              target: sumTarget + response.data.INDOBATTERY[0].target + response.data.INDOTIRE[0].target,
              total_amount_first: sum_amount_first + response.data.INDOBATTERY[0].total_amount_first + response.data.INDOTIRE[0].total_amount_first,
              total_qty_first: sum_qty_first + response.data.INDOBATTERY[0].total_qty_first + response.data.INDOTIRE[0].total_qty_first,
              total_amount_second: sum_amount_second + response.data.INDOBATTERY[0].total_amount_second + response.data.INDOTIRE[0].total_amount_second,
              total_qty_second: sum_qty_second + response.data.INDOBATTERY[0].total_qty_second + response.data.INDOTIRE[0].total_qty_second,
              total_amount_thirth: sum_amount_thirth + response.data.INDOBATTERY[0].total_amount_thirth + response.data.INDOTIRE[0].total_amount_thirth,
              total_qty_thirth: sum_qty_thirth + response.data.INDOBATTERY[0].total_amount_thirth + response.data.INDOTIRE[0].total_qty_thirth,
              fo: sumFO + response.data.INDOBATTERY[0].fo + response.data.INDOTIRE[0].fo,
              tgt_ach: 0,
              incent: 0,
              incentive: sumIncentive + response.data.INDOBATTERY[0].incentive + response.data.INDOTIRE[0].incentive,
            })
            commit('SET_DESSERTS_EDIT', {INDOPART: arr, INDOBATTERY: response.data.INDOBATTERY, INDOTIRE: response.data.INDOTIRE, TOTAL: total})
            resolve(response.status)
          }
        })
    })
  },
  Updated({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      $axios.patch(`/bonus-dealer/${payload[1].id}`, payload[1])
        .then((response) => {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            dispatch("Edit", payload[0])
            resolve(response.status)
          }
        })
    })
  },
  printpdf({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      // $axios.patch(`/bonus-dealers/pdf/${payload.dealers}/${payload.quartals}/${payload.year}`, state.form_pdf)
      $axios.patch(`/bonus-dealers/pdf/${payload.dealers}/${payload.quartals}/${payload.year}`, state.form_pdf, {
        responseType: 'blob'
      })
        .then((response) => {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            resolve(response)
          }
        })
        .catch(function (error) {
          if (error.response.status === 422) {
            commit('SET_ERROR_FORM_PDF', error.response.data)
          }
          resolve(error.response)
        });
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}

