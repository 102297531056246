<template>
  <div>
    <table-regular-order v-if="this.$route.params.initial === 'ro' "/>
    <table-fixed-order v-if="this.$route.params.initial === 'fo' "/>
  </div>
</template>
<script>
import ListDetailRO from "./components/ListDetailRO";
import ListDetailFO from "./components/ListDetailFO";
export default {
  created() {
    var md5 = require("md5");
    console.log(
      "initial:" +
        this.$route.params.initial +
        "--- id:" +
        md5(this.$route.params.id)
    );
  },
  components: {
    'table-regular-order':ListDetailRO,
    'table-fixed-order':ListDetailFO,
  },
};
</script>