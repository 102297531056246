<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{
            id ? "Update Users" : "Create Users"
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field 
                v-model="form.name" 
                label="Fullname*" 
                required 
                :error-messages="errors.name"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="form.role"
                  :items="roles"
                  item-text="name"
                  item-value="id"
                  label="Roles Permission*"
                  required
                  :error-messages="errors.role"
                  @change="vis(form.role)"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-radio-group v-model="form.type" row mandatory :error-messages="errors.type">
                  <v-radio label="Front Office" value="front-office" v-if="visibility === true"></v-radio>
                  <v-radio label="Back Office" value="back-office" v-if="visibility === false"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" v-if="visibility === true">
                <v-radio-group
                  v-model="form.r"
                  mandatory
                  row
                  :error-messages="errors.r"
                >
                  <v-radio
                    label="Roda-2"
                    value="roda-2"
                  ></v-radio>
                  <v-radio
                    label="Roda-4"
                    value="roda-r4"
                  ></v-radio>
                  <v-radio
                    label="Roda-6"
                    value="roda-6"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" v-if="visibility === true">
                <tableShipTo></tableShipTo>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field 
                v-model="form.email" 
                label="Email*" 
                required 
                :rules="errors.email"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="form.password"
                  label="Password*"
                  type="password"
                  required
                  :rules="errors.password"
                />
              </v-col>
              <v-col cols="12" md="4" v-if="visibility === true">
                <v-text-field
                  v-model="form.accountID"
                  label="Account ID*"
                  required
                  :rules="errors.accountID"
                />
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="id ? update(id) : create()">
            {{ id ? "Updated" : "Created" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapActions } from "vuex";
import tableShipTo from './tableShipTo';
export default {
  components:{
    tableShipTo
  },
  data () {
    return {
      visibility: false
    }
  },
  computed: {
    ...mapState(["loading","errors"]),
    ...mapState("user", {
      dialog: (state) => state.dialog.show,
      id: (state) => state.dialog.id,
      form: (state) => state.form,
      roles: (state) => state.roles,
      itemShipto: (state) => state.itemShipto,
    }),
    form: {
      get: function () {
        return this.$store.state.user.form;
      },
      set: function (value) {
        this.$store.commit("user/SET_FORM", value );
      },
    },
    dialog: {
      get: function () {
        return this.$store.state.user.dialog.show;
      },
      set: function (value) {
        this.$store.commit("user/SET_DIALOG", { show: value });
      },
    },
    id: {
      get: function () {
        return this.$store.state.user.dialog.id;
      },
      set: function (value) {
        this.$store.commit("user/SET_DIALOG", { id: value });
      },
    }
  },
  watch:{
    dialog(newValue){
      if(newValue === false){
        this.$store.commit("user/CLEAR_FORM");
      }
    },
    form(v){
      this.visibility=(parseInt(v.role) === 3)?true:false;
    }
  },
  methods: {
    ...mapActions("user", ["createOrUpdate"]),
    vis(e){
      this.visibility=(e === 3)?true:false;
    },
    create() {
      this.createOrUpdate().then((response) => {
        if(response === 200){
          this.$store.commit("user/SET_DIALOG", { show: false });
        }
      })
    },
    update(id) {
      this.createOrUpdate(id).then((response) => {
        if(response === 200){
          this.$store.commit("user/SET_DIALOG", { show: false });
        }
      });
    },
  },
};
</script>