<template>
    <v-alert
      v-if="alertbanner.alert"
      dense
      text
      :type="alertbanner.status"
    >
      {{ alertbanner.message }}
    </v-alert>
</template>
<script>
import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState(['alertbanner']),
        alertbanner: {
            get(){
                return this.$store.state.alertbanner
            },
            set(v){
                return v
            } 
        }
    }
}
</script>