<template>
  <v-app v-bind:class="{ login: !isAuth }">
    <app-drawer-dekstop v-if="isAuth && $vuetify.breakpoint.mdAndUp" />
    <app-drawer-mobile v-if="isAuth && $vuetify.breakpoint.mdAndDown" />
    <app-bar v-if="isAuth" />
    <v-main class="mainBackground">
      <app-modal-medium />
      <app-snackbar />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Appbar from "./Appbar.vue";
import DrawerDekstop from "./DrawerDekstop.vue";
import DrawerMobile from "./DrawerMobile.vue";
import Snackbar from "./Snackbar.vue";
import DialogMedium from "./DialogMedium.vue";
export default {
  computed: {
    ...mapGetters(["isAuth"]),
  },
  components: {
    "app-bar": Appbar,
    "app-drawer-dekstop": DrawerDekstop,
    "app-drawer-mobile": DrawerMobile,
    "app-snackbar": Snackbar,
    "app-modal-medium": DialogMedium,
  },
};
</script>
<style lang="sass" scoped>
.login
  background: rgb(7,0,119)
  background: linear-gradient(90deg, rgba(7,0,119,1) 0%, rgba(105,105,255,1) 48%, rgba(0,207,250,1) 100%)
</style>