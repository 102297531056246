<template>
  <v-container fluid tag="section">
    <v-row class="d-flex justify-content-end px-3 mt-3">
      <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card class="rounded-lg card mt-3">
          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field label="Email Address" class="purple-input" v-model="formaccount.email" :error-messages="errors.email"/>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field label="Full Name" class="purple-input" v-model="formaccount.name" :error-messages="errors.name"/>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field label="Password" class="purple-input" v-model="formaccount.password" :error-messages="errors.password"/>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field label="Confirm Password" class="purple-input" v-model="formaccount.confirm_password" :error-messages="errors.confirm_password"/>
                </v-col>

                <v-col cols="12" class="text-right">
                  <v-btn rounded class="mr-0" v-on:click="account()"> Update Profile </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card class="rounded-lg card">
          <v-card-text class="text-center">
            <v-avatar color="orange" size="100">
              <img :src="baseUrl+'/storage/img/User Avatar_Two Color.svg'" alt="John" />
            </v-avatar>
            <p class="mt-3">{{ authenticated.email }}|{{ authenticated.name }}</p>

            <p class="font-weight-light grey--text">
              Don't be scared of the truth...
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href:
          window.location.protocol + "//" + window.location.hostname + ":8000/",
      },
      {
        text: "Users",
        disabled: false,
        href:
          window.location.protocol +
          "//" +
          window.location.hostname +
          ":8000/users",
      },
    ],
  }),
  computed: {
    ...mapGetters(["isAuth"]),
    ...mapState(["errors"]),
    ...mapState("auth", {
      authenticated: (state) => state.authenticated,
      formaccount: (state) => state.formaccount,
    }),
    ...mapState({
      baseUrl: (state) => state.baseUrl,
      drawer: (state) => state.drawer,
    }),
  },
  methods: {
    ...mapActions('auth', ['update_account']),
    account(){
      this.update_account().then((res)=>{
        alert(res.data.status)
      });
    }
  }
};
</script>
