import $axios from '../core/api.js'

const state = () => ({

})

const mutations = {

}

const actions = {
    chartCall({ commit },payload) {
        return new Promise((resolve) => {
            $axios.post('/order-barchart', payload)
            .then((response) => {
                if (response.status === 200 && response.data.status === "Token is Expired") {
                    setTimeout(() => {
                        localStorage.setItem('token', null)
                        commit('SET_TOKEN', null, { root: true })
                    }, 1000)
                    resolve(401)
                } else {
                    resolve(response.data)
                }
            })
            .catch((error) => {
                console.log(error);
            })
        })
    },
    getFOandRODealers({ commit },payload) {
        return new Promise((resolve) => {
            $axios.get(`/order-get-on-dealers?dealers=${payload.dealers}&tahun=${payload.tahun}&semester=${payload.semester}&kuartal=${payload.kuartal}`)
            .then((response) => {
                if (response.status === 200 && response.data.status === "Token is Expired") {
                    setTimeout(() => {
                        localStorage.setItem('token', null)
                        commit('SET_TOKEN', null, { root: true })
                    }, 1000)
                    resolve(401)
                } else {
                    resolve(response.data)
                }
            })
            .catch((error) => {
                console.log(error);
            })
        })
    },
    getDealers({ commit }) {
        return new Promise((resolve) => {
            $axios.get('/order-get-dealers')
            .then((response) => {
                if (response.status === 200 && response.data.status === "Token is Expired") {
                    setTimeout(() => {
                        localStorage.setItem('token', null)
                        commit('SET_TOKEN', null, { root: true })
                    }, 1000)
                    resolve(401)
                } else {
                    resolve(response.data)
                }
            })
            .catch((error) => {
                console.log(error);
            })
        })
    }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}

