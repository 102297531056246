var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center"},[_c('p',[_vm._v("RINCIAN")])]),_vm._v(" "),(_vm.loading)?_c('v-card-text',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"purple","indeterminate":""}})],1):_vm._e(),_vm._v(" "),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("PRODUCT GROUP")]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v("TARGET")]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v("QTY "+_vm._s(_vm.bulan1(_vm.$route.params.quartals)))]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.bulan1(_vm.$route.params.quartals)))]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v("QTY "+_vm._s(_vm.bulan2(_vm.$route.params.quartals)))]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.bulan2(_vm.$route.params.quartals)))]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v("QTY "+_vm._s(_vm.bulan3(_vm.$route.params.quartals)))]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.bulan3(_vm.$route.params.quartals)))]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v("TOTAL FO Q4 2020")]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v("%TGT-ACH%")]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v("%INCENT")]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v("INCENTIVE")])])]),_vm._v(" "),_c('tbody',[_vm._l((_vm.desserts_edit.INDOPART),function(items){return _c('tr',{key:items.kelompok_produk},[_c('td',{class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(items.kelompok_produk)+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.target)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_qty_first)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_amount_first)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_qty_second)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_amount_second)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_qty_thirth)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_amount_thirth)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.fo)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.tgt_ach)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"%\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.incent)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"%\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.incentive)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")])])}),_vm._v(" "),_vm._l((_vm.desserts_edit.INDOBATTERY),function(items){return _c('tr',{key:items.kelompok_produk},[_c('td',{class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(items.kelompok_produk)+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.target)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_qty_first)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_amount_first)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_qty_second)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_amount_second)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_qty_thirth)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_amount_thirth)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.fo)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.tgt_ach)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"%\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.incent)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"%\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.incentive)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")])])}),_vm._v(" "),_vm._l((_vm.desserts_edit.INDOTIRE),function(items){return _c('tr',{key:items.kelompok_produk},[_c('td',{class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(items.kelompok_produk)+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.target)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_qty_first)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_amount_first)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_qty_second)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_amount_second)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_qty_thirth)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_amount_thirth)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.fo)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.tgt_ach)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"%\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.incent)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"%\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.incentive)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")])])}),_vm._v(" "),_vm._l((_vm.desserts_edit.TOTAL),function(items){return _c('tr',{key:items.kelompok_produk},[_c('td',{class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(items.kelompok_produk)+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.target)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_qty_first)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_amount_first)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_qty_second)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_amount_second)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_qty_thirth)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.total_amount_thirth)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.fo)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.tgt_ach)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"%\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.incent)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"%\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-right",class:[
              _vm.$vuetify.theme.dark ? 'text-light' : 'text-dark' ]},[_vm._v("\n              "+_vm._s(parseFloat(items.incentive)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+"\n            ")])])})],2)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }