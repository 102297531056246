import $axios from '../core/api.js'

const state = () => ({
  // STATE TABLE STARTED
  totalDesserts: 0,
  desserts: [],
  search: '',
  options: {},
  headers: [
    {
      text: 'Name',
      align: 'start',
      sortable: false,
      value: 'name',
    },
    { text: 'Type', value: 'type' },
    { text: 'Icon', value: 'icon' },
    { text: 'Link', value: 'link' },
    { text: 'Authorization', value: 'authorization' },
    { text: '#', value: 'actions', sortable: false },
  ],
  // STATE TABLE ENDED
  // STATE FORM STARTED
  dialog: {
    show: false,
    param: 0
  },
  form: {
    name: '',
    type: '',
    extend: '',
    icon: '',
    link: '',
    authorization: ''
  },
  authorization: [],
  extend: [],
  // STATE FORM ENDED
})

const mutations = {
  // MUTATION TABLE STARTED
  SET_DATATABLES(state, payload) {
    state.totalDesserts = payload.totalDesserts,
      state.desserts = payload.desserts
  },
  SET_OPTIONS(state, payload) {
    state.options = payload
  },
  SET_SEARCH(state, payload) {
    state.search = payload
  },
  SET_DIALOG(state, payload) {
    state.dialog = {
      show: payload.show,
      id: payload.id
    }
  },
  SET_FORM(state, payload) {
    state.form = {
      name: payload.name,
      type: payload.type,
      extend: payload.extend,
      icon: payload.icon,
      link: payload.link,
      authorization: payload.authorization
    }
  },
  CLEAR_FORM(state, payload) {
    state.form = {
      name: '',
      type: '',
      extend: '',
      icon: '',
      link: '',
      authorization: ''
    }
  },
  SET_EXTEND(state, payload) {
    state.extend = payload
  },
  SET_AUTHORIZATION(state, payload) {
    state.authorization = payload
  },
}

const actions = {
  getmenu({ commit }) {
    return new Promise((resolve, reject) => {
      $axios.get(`/get-menu`)
        .then((response) => {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            commit('SET_EXTEND', response.data)
            resolve(response.status)
          }
        })
    })
  },
  getauthorization({ commit }) {
    return new Promise((resolve, reject) => {
      $axios.get(`/get-authorization`)
        .then((response) => {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            commit('SET_AUTHORIZATION', response.data)
            resolve(response.status)
          }
        })
    })
  },
  Index({ commit, state }) {
    commit('SET_LOADING', true, { root: true })
    return new Promise((resolve, reject) => {
      const { sortBy, sortDesc, page, itemsPerPage } = state.options;
      $axios
        .get(`/menus?page=${page}&perpage=${itemsPerPage}&search=${state.search}`)
        .then(function (response) {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            const setdata = {
              desserts: response.data.data,
              totalDesserts: response.data.meta.total
            };
            commit('SET_DATATABLES', setdata)
            commit('SET_LOADING', false, { root: true })
            resolve(response.status)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  },
  createOrUpdate({ dispatch, commit, state }, payload) {
    return new Promise((resolve, reject) => {
      if (payload === undefined) {
        $axios.post(`/menus`, state.form)
          .then(function (response) {
            if (response.status === 200 && response.data.status === "Token is Expired") {
              setTimeout(() => {
                localStorage.setItem('token', null)
                commit('SET_TOKEN', null, { root: true })
              }, 1000)
              resolve(401)
            } else {
              dispatch('Index')
              resolve(response.status)
              commit('SET_ALERTBANNER', response.data, { root: true })
              setTimeout(() => {
                commit('CLEAR_ALERTBANNER', response.data, { root: true })
              }, 3000);
            }
          })
          .catch(function (error) {
            if (error.response) {
              commit('SET_ERRORS', error.response.data.errors, { root: true })
              resolve(error.response.status)
            }
          });
      } else {
        $axios.put(`/menus/${payload}`, state.form)
          .then(function (response) {
            if (response.status === 200 && response.data.status === "Token is Expired") {
              setTimeout(() => {
                localStorage.setItem('token', null)
                commit('SET_TOKEN', null, { root: true })
              }, 1000)
              resolve(401)
            } else {
              dispatch('Index')
              resolve(response.status)
              commit('SET_ALERTBANNER', response.data, { root: true })
              setTimeout(() => {
                commit('CLEAR_ALERTBANNER', response.data, { root: true })
              }, 3000);
            }
          })
          .catch(function (error) {
            if (error.response) {
              commit('SET_ERRORS', error.response.data.errors, { root: true })
              resolve(error.response.status)
            }
          });
      }
    })
  },
  show({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $axios.get(`/menus/${payload}/edit`)
        .then((response) => {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            commit('SET_FORM', response.data)
            resolve(response.status)
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status)
          }
        });
    })
  },
  delete({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      $axios.post(`/menus/destroy`, payload)
        .then((response) => {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            dispatch('Index')
            resolve(response.status)
            commit('SET_ALERTBANNER', response.data, { root: true })
            setTimeout(() => {
              commit('CLEAR_ALERTBANNER', response.data, { root: true })
            }, 3000);
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status)
          }
        });
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}

