<template>
  <v-data-table
    dense
    v-model="selected"
    :single-select="select"
    item-key="id"
    show-select
    :headers="headers"
    :items="desserts"
    :search="search"
    :options.sync="options"
    :server-items-length="totalDesserts"
    :loading="loading"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Ship To Master Recycle Bin</v-toolbar-title>
        <v-switch v-model="select" class="pa-3 mt-7" label="Select"></v-switch>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-tooltip top v-if="selected.length > 0 && $can('shipmaster-delete')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              x-small
              @click="deleted()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Force Delete</span>
        </v-tooltip>
        <v-tooltip top v-if="selected.length > 0 && $can('shipmaster-delete')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              x-small
              @click="restore()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark> mdi-restore </v-icon>
            </v-btn>
          </template>
          <span>Restore Data</span>
        </v-tooltip>
        <v-spacer />
        <app-alert />
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to permanent delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete = false"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:[`item.sales_district_id`]="{ item }">
      <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.sales_district_id }}</p>
    </template>
    <template v-slot:[`item.custgroup`]="{ item }">
      <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.custgroup }}</p>
    </template>
    <template v-slot:[`item.accountnum`]="{ item }">
      <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.accountnum }}</p>
    </template>
    <template v-slot:[`item.aliases`]="{ item }">
      <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.aliases }}</p>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.name }}</p>
    </template>
  </v-data-table>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    "app-alert": Alert,
  },
  data() {
    return {
      selected: [],
      select: false,
      dialogDelete: false,
    };
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState("shiptoRecycleBin", {
      totalDesserts: (state) => state.totalDesserts,
      desserts: (state) => state.desserts,
      search: (state) => state.search,
      options: (state) => state.options,
      headers: (state) => state.headers,
    }),
    options: {
      get: function () {
        return this.$store.state.shiptoRecycleBin.options;
      },
      set: function (value) {
        this.$store.commit("shiptoRecycleBin/SET_OPTIONS", value);
      },
    },
    search: {
      get: function () {
        return this.$store.state.shiptoRecycleBin.search;
      },
      set: function (value) {
        this.$store.commit("shiptoRecycleBin/SET_SEARCH", value);
      },
    },
    loading: {
      get: function () {
        return this.$store.state.loading;
      },
      set: function (value) {
        this.$store.commit("SET_LOADING", value);
      },
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search() {
      this.getDataFromApi();
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    ...mapActions("shiptoRecycleBin", ["Index", "restored", "forcedelete"]),
    ...mapActions("auth", ["logout"]),
    getDataFromApi() {
      this.Index().then((e) => {
        if (e != 200) {
          this.logout().then((r) => {
            this.$router.push({ name: "auth.login" });
          });
        }
      });
    },
    restore() {
      this.restored(this.selected).then((e) => {
        if (e === 200) {
          this.dialogDelete = false;
        }
      });
    },
    deleted() {
      this.dialogDelete = true;
    },
    deleteConfirm() {
      this.forcedelete(this.selected).then((e) => {
        if (e === 200) {
          this.dialogDelete = false;
        }
      });
    },
  },
};
</script>