<template>
  <v-container>
    <v-row class="d-flex justify-content-end px-3 mt-3">
      <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
    </v-row>
    <app-form />
    <router-view></router-view>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import Form from "./Form.vue";
export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href:
          window.location.protocol + "//" + window.location.hostname + ":8000/",
      },
      {
        text: "Users",
        disabled: false,
        href:
          window.location.protocol +
          "//" +
          window.location.hostname +
          ":8000/users",
      },
    ],
  }),
  computed: {
    ...mapGetters(["isAuth"]),
    ...mapState(["baseUrl"]),
  },
  components: {
    "app-form": Form,
  },
};
</script>