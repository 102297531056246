<template>
  <div>
    <v-card class="rounded-lg" flat>
      <v-card-title>
        <v-row>
          <v-col cols="12" md="4" sm="12">
            <v-select
              v-model="dealers_param"
              :items="dealers"
              item-text="name"
              item-value="id"
              menu-props="auto"
              label="Select"
              hide-details
              prepend-icon="mdi-store"
              single-line
            ></v-select>
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <v-select
              v-model="quartals_param"
              :items="quartalState"
              item-text="initial"
              item-value="value"
              menu-props="auto"
              label="Select"
              hide-details
              prepend-icon="mdi-calendar-month "
              single-line
            ></v-select>
          </v-col>
          <v-col cols="12" md="3" sm="12">
            <v-select
              v-model="years_param"
              :items="yearState"
              menu-props="auto"
              label="Select"
              hide-details
              prepend-icon="mdi-calendar-month "
              single-line
            ></v-select>
          </v-col>
          <v-col cols="12" md="1" sm="12">
            <v-btn color="primary" class="mt-3" outlined @click="getDataFromApi">
              <v-icon>mdi-repeat</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">Kelompok Produk</th>
                <th class="text-center">Target</th>
                <th class="text-center">FO</th>
                <th class="text-center">%TGT-ACH</th>
                <th class="text-center">%INCENT</th>
                <th class="text-center">Incentive</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in desserts.INDOPART"
                :key="item.kelompok_produk"
                :class="getColor(item.kelompok_produk)"
              >
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{ item.kelompok_produk }}
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{
                    item.target != 0
                      ? parseFloat(item.target)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "-"
                  }}
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{
                    item.fo != 0
                      ? parseFloat(item.fo)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "-"
                  }}
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{ item.tgt_ach }} %
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{ item.incent }} %
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{
                    parseFloat(item.incentive) != 0
                      ? parseFloat(item.incentive)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "-"
                  }}
                </td>
              </tr>
              <tr
                v-for="item in desserts.INDOBATTERY"
                :key="item.kelompok_produk"
                :class="getColor(item.product)"
              >
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{ item.kelompok_produk }}
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{
                    item.target != 0
                      ? parseFloat(item.target)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "-"
                  }}
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{
                    item.fo != 0
                      ? parseFloat(item.fo)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "-"
                  }}
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{ item.tgt_ach }} %
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{ item.incent }} %
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{
                    parseFloat(item.incentive) != 0
                      ? parseFloat(item.incentive)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "-"
                  }}
                </td>
              </tr>
              <tr
                v-for="item in desserts.INDOTIRE"
                :key="item.kelompok_produk"
                :class="getColor(item.product)"
              >
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{ item.kelompok_produk }}
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{
                    item.target != 0
                      ? parseFloat(item.target)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "-"
                  }}
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{
                    item.fo != 0
                      ? parseFloat(item.fo)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "-"
                  }}
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{ item.tgt_ach }} %
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{ item.incent }} %
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{
                    parseFloat(item.incentive) != 0
                      ? parseFloat(item.incentive)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "-"
                  }}
                </td>
              </tr>
              <tr
                v-for="item in desserts.TOTAL"
                :key="item.kelompok_produk"
                :class="getColor(item.product)"
              >
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{ item.kelompok_produk }}
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{
                    item.target != 0
                      ? parseFloat(item.target)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "-"
                  }}
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{
                    item.fo != 0
                      ? parseFloat(item.fo)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "-"
                  }}
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{ item.tgt_ach }} %
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{ item.incent }} %
                </td>
                <td
                  v-bind:class="[
                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                  ]"
                >
                  {{
                    parseFloat(item.incentive) != 0
                      ? parseFloat(item.incentive)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : "-"
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-card-actions
        class="d-flex justify-center"
        v-if="dealers_param != '' && quartals_param != '' && years_param != ''"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon fab v-bind="attrs" v-on="on" @click="pdfDialog()">
              <v-icon>mdi-file-pdf-box</v-icon>
            </v-btn>
          </template>
          <span>Export to PDF</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon fab v-bind="attrs" v-on="on" @click="details()">
              <v-icon>mdi-clipboard-text-search</v-icon>
            </v-btn>
          </template>
          <span>Rincian</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
    <!-- dialog pdf::START -->
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Export PDF file </v-card-title>
        <v-card-text>
          <v-text-field
            label="Nomor Surat"
            v-model="form_pdf.print_numbers"
            :error-messages="error_form_pdf.print_numbers"
            required
            hint="example: 574/CSA-IPD/XI/21"
          ></v-text-field>
          <v-text-field
            label="Atas nama pemberi hormat (Responsible)"
            v-model="form_pdf.name_responsible"
            :error-messages="error_form_pdf.name_responsible"
            required
          ></v-text-field>
          <v-text-field
            label="Atas nama penyetujui (Customer)"
            v-model="form_pdf.name_customers"
            :error-messages="error_form_pdf.name_customers"
            required
          ></v-text-field>
          <v-textarea
            v-model="form_pdf.address_company_customers"
            label="Alamat Lengkap"
            value="alamat lengkap perusahaan (custemers)"
            :error-messages="error_form_pdf.address_company_customers"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="pdf()" :loading="loadpdf"> print </v-btn>
          <v-btn color="error" text @click="dialog = false"> close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog pdf::PDF -->
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      dealers_param: "",
      quartals_param: "",
      years_param: "",
      quartalState: [
        { initial: "Q1/Januari-Maret", value: "01-03" },
        { initial: "Q2/April-Juni", value: "04-06" },
        { initial: "Q3/Juli-September", value: "07-09" },
        { initial: "Q4/Oktober-Desember", value: "10-12" },
      ],
      yearState: [],
      dialog: false,
      loadpdf: false,
    };
  },
  computed: {
    ...mapState("quartals", {
      dealers: (state) => state.dealers,
      desserts: (state) => state.desserts,
      form_pdf: (state) => state.form_pdf,
      error_form_pdf: (state) => state.error_form_pdf,
    }),
    error_form_pdf: {
      get: function () {
        return this.$store.state.quartals.error_form_pdf;
      },
      set: function (value) {
        this.$store.commit("quartals/SET_ERROR_FORM_PDF", value);
      },
    },
  },
  mounted() {
    this.getDealers();
    this.setYears();
    // this.getDataFromApi();
  },
  methods: {
    ...mapActions("quartals", ["getDealers", "Index", "printpdf"]),
    ...mapActions("auth", ["logout"]),
    getColor(kel_prod) {
      if (kel_prod === "INDOPARTS") return "green";
      else if (kel_prod === "INDOBATTERY") return "green";
      else if (kel_prod === "INDOTIRE") return "green";
      else if (kel_prod === "TOTAL") return "primary";
    },
    getDataFromApi() {
      const params = {
        dealers: this.dealers_param,
        quartals: this.quartals_param,
        year: this.years_param,
      };
      this.Index(params).then((e) => {
        if (e != 200) {
          this.logout().then((r) => {
            this.$router.push({ name: "auth.login" });
          });
        }
      });
    },
    details() {
      let dealers_param = this.dealers_param;
      let quartals_param = this.quartals_param;
      let years_param = this.years_param;
      this.$router.push({
        name: "quartals.details",
        params: {
          dealer: dealers_param,
          quartals: quartals_param,
          years: years_param,
        },
      });
    },
    edit() {
      let dealers_param = this.dealers_param;
      let quartals_param = this.quartals_param;
      let years_param = this.years_param;
      this.$router.push({
        name: "quartals.edit",
        params: {
          dealer: dealers_param,
          quartals: quartals_param,
          years: years_param,
        },
      });
    },
    pdfDialog() {
      this.dialog = true;
    },
    pdf() {
      this.loadpdf=true
      const params = {
        dealers: this.dealers_param,
        quartals: this.quartals_param,
        year: this.years_param,
      };
      this.printpdf(params).then((res) => {
        console.log(res.data);
        this.loadpdf=false
        this.dialog =false;
        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
    },
    setYears() {
      var max = new Date().getFullYear();
      var min = max - 9;
      var years = this.yearState;
      for (var i = max; i >= min; i--) {
        years.push(i);
      }
    },
  },
};
</script>