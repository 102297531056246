<template>
  <v-navigation-drawer
    v-model="drawer"
    class="drawer"
    app
  >
    <!-- list menu -->
    <v-list-item-title>
      <v-btn
        x-large
        block
        class="pt-2 drawer"
      >
        <v-img
          alt="Vuetify Logo"
          class="shrink"
          v-bind:class="[!drawer ? 'mr-2' : '']"
          contain
          :src="($vuetify.theme.dark)?baseUrl+'/storage/img/logo.png':baseUrl+'/storage/img/logo-indoparts.png'"
          transition="scale-transition"
          :width="(drawer)?'200':'200'"
        />
      </v-btn>
    </v-list-item-title>

    <v-divider></v-divider>

    <v-list nav dense rounded>
      <v-list-item-group v-model="selectedItem">
        <v-list-item to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- menu -->
        <v-list-group
          v-for="(item, i) in menu"
          :key="i"
          :value="false"
          :prepend-icon="item.icon"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </template>
          <div v-for="(sub, i) in item.extend" :key="i">
            <v-list-item v-if="$can(sub.authorization)" :to="sub.link">
              <v-list-item-icon>
                <v-icon>{{ sub.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ sub.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-group>
        <!-- menu -->
      </v-list-item-group>
    </v-list>
    <!-- list menu -->
  </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      selectedItem: 1,
      mini: true,
    };
  },
  computed: {
    ...mapGetters(["isAuth"]),
    ...mapState(["drawer","baseUrl"]),
    ...mapState("auth", {
      menu: (state) => state.menu,
      authenticated: (state) => state.authenticated,
    }),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(v) {
        this.$store.commit("SET_DRAWER", v);
      },
    },
  },
};
</script>