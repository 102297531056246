<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{
            id ? "Update menus" : "Create menus"
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12" :md="form.type === 'sub-menu' ? 4 : 6">
                    <v-text-field v-model="form.name" label="Menu Name" />
                  </v-col>
                  <v-col cols="12" :md="form.type === 'sub-menu' ? 4 : 6">
                    <v-select
                      v-model="form.type"
                      :items="type"
                      item-text="name"
                      item-value="value"
                      label="Select Type"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    :md="form.type === 'sub-menu' ? 4 : 6"
                    v-if="form.type === 'sub-menu'"
                  >
                    <v-select
                      v-model="form.extend"
                      :items="extend"
                      item-text="name"
                      item-value="id"
                      label="Select Extend"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" :md="form.type === 'sub-menu' ? 4 : 6">
                    <v-text-field v-model="form.icon" label="Menu Icon" />
                  </v-col>
                  <v-col
                    cols="12"
                    :md="form.type === 'sub-menu' ? 4 : 6"
                    v-if="form.type === 'sub-menu'"
                  >
                    <v-text-field v-model="form.link" label="Menu Link" />
                  </v-col>
                  <v-col cols="12" :md="form.type === 'sub-menu' ? 4 : 6">
                    <v-select
                      v-model="form.authorization"
                      :items="authorization"
                      item-text="name"
                      item-value="name"
                      label="Select Authorization"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="id ? update(id) : create()">
            {{ id ? "Updated" : "Created" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data: () => ({
    type: [
      { name: "Main Menu", value: "main-menu" },
      { name: "Sub Menu", value: "sub-menu" },
    ],
  }),
  computed: {
    ...mapState(["loading", "errors"]),
    ...mapState("menu", {
      dialog: (state) => state.dialog.show,
      id: (state) => state.dialog.id,
      form: (state) => state.form,
      authorization: (state) => state.authorization,
      extend: (state) => state.extend,
    }),
    dialog: {
      get: function () {
        return this.$store.state.menu.dialog.show;
      },
      set: function (value) {
        this.$store.commit("menu/SET_DIALOG", { show: value });
      },
    },
    id: {
      get: function () {
        return this.$store.state.menu.dialog.id;
      },
      set: function (value) {
        this.$store.commit("menu/SET_DIALOG", { id: value });
      },
    },
  },
  watch: {
    dialog(n) {
      if (n === false) {
        this.$store.commit("menu/CLEAR_FORM");
      }
    },
  },
  methods: {
    ...mapActions("menu", ["createOrUpdate"]),
    create() {
      this.createOrUpdate().then((response) => {
        if (response === 200) {
          this.$store.commit("menu/SET_DIALOG", { show: false });
        }
      });
    },
    update(id) {
      this.createOrUpdate(id).then((response) => {
        if (response === 200) {
          this.$store.commit("menu/SET_DIALOG", { show: false });
        }
      });
    },
  },
};
</script>