<template>
  <v-card class="rounded-lg card" flat>
    <v-card-title>
      Menu Manage
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      dense
      v-model="selected"
      :single-select="select"
      item-key="id"
      show-select
      :headers="headers"
      :items="desserts"
      :search="search"
      :options.sync="options"
      :server-items-length="totalDesserts"
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-switch
            v-model="select"
            label="Single select"
            class="pa-3 mt-7"
          ></v-switch>
          <v-btn
            class="mx-2"
            color="primary"
            fab
            small
            @click="create()"
            v-if="$can('menu-create')"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn
            class="mx-2"
            fab
            small
            color="error"
            v-if="selected.length > 0 && $can('menu-delete')"
            @click="deleted()"
          >
            <v-icon dark> mdi-delete </v-icon>
          </v-btn>
          <v-spacer />
          <app-alert />
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete = false">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.name }}</p>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.type }}</p>
      </template>
      <template v-slot:[`item.icon`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.icon }}</p>
      </template>
      <template v-slot:[`item.link`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.link }}</p>
      </template>
      <template v-slot:[`item.authorization`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.authorization }}</p>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="Edit(item.id)">
          mdi-chevron-double-right
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    "app-alert": Alert,
  },
  data() {
    return {
      selected: [],
      select: false,
      dialogDelete: false
    };
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState("menu", {
      totalDesserts: (state) => state.totalDesserts,
      desserts: (state) => state.desserts,
      search: (state) => state.search,
      options: (state) => state.options,
      headers: (state) => state.headers,
    }),
    options: {
      get: function () {
        return this.$store.state.menu.options;
      },
      set: function (value) {
        this.$store.commit("menu/SET_OPTIONS", value);
      },
    },
    search: {
      get: function () {
        return this.$store.state.menu.search;
      },
      set: function (value) {
        this.$store.commit("menu/SET_SEARCH", value);
      },
    },
    loading: {
      get: function () {
        return this.$store.state.loading;
      },
      set: function (value) {
        this.$store.commit("SET_LOADING", value);
      },
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search() {
      this.getDataFromApi();
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    ...mapActions("menu", ["Index", "getmenu", "getauthorization", "show", "delete"]),
    ...mapActions("auth", ["logout"]),
    getDataFromApi() {
      this.Index().then((e)=>{
        if (e != 200) {
          this.logout().then((r) => {
            this.$router.push({ name: 'auth.login' }) 
          })
        }
      });
    },
    create() {
      this.getmenu().then((e)=>{
        if (e != 200) {
          this.logout().then((r) => {
            this.$router.push({ name: 'auth.login' }) 
          })
        }
      });
      this.getauthorization().then((e)=>{
        if (e != 200) {
          this.logout().then((r) => {
            this.$router.push({ name: 'auth.login' }) 
          })
        }
      });
      this.$store.commit("menu/SET_DIALOG", { show: true });
    },
    Edit(e) {
      this.getmenu().then((r)=>{
        if (r != 200) {
          this.logout().then((s) => {
            this.$router.push({ name: 'auth.login' }) 
          })
        }
      });
      this.getauthorization().then((r)=>{
        if (r != 200) {
          this.logout().then((s) => {
            this.$router.push({ name: 'auth.login' }) 
          })
        }
      });
      this.show(e).then((x)=>{
        if (x != 200) {
          this.logout().then((y) => {
            this.$router.push({ name: 'auth.login' }) 
          })
        }
      });
      this.$store.commit("menu/SET_DIALOG", { show: true, id: e });
    },
    deleted(){
      this.dialogDelete=true
    },
    deleteConfirm() {
      this.delete(this.selected).then((e) => {
        if(e === 200){
          this.dialogDelete=false
        }
      })
    }
  },
};
</script>
