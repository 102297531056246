import $axios from '../../core/api.js'

const state = () => ({
  // STATE TABLE STARTED
  totalDesserts: 0,
  desserts: [],
  search: '',
  options: {},
  headers: [
    {
      text: 'ID',
      align: 'start',
      sortable: false,
      value: 'sales_district_id',
    },
    { text: 'GROUP', value: 'custgroup' },
    { text: 'NO', value: 'accountnum' },
    { text: 'ALIAS', value: 'aliases' },
    { text: 'COMPANY', value: 'name' },
  ],
  // STATE TABLE ENDED
  // STATE FORM STARTED
  dialog: {
    show: false,
    param: 0
  },
  form: {
    id: '',
    aliases: '',
  }
  // STATE FORM ENDED
})

const mutations = {
  // MUTATION TABLE STARTED
  SET_DATATABLES(state, payload) {
    state.totalDesserts = payload.totalDesserts,
      state.desserts = payload.desserts
  },
  SET_OPTIONS(state, payload) {
    state.options = payload
  },
  SET_SEARCH(state, payload) {
    state.search = payload
  },
  SET_DIALOG(state, payload) {
    state.dialog = {
      show: payload.show,
      id: payload.id
    }
  },
  SET_FORM(state, payload) {
    state.form = {
      id: payload.id,
      aliases: payload.aliases,
    }
  },
  CLEAR_FORM(state) {
    state.form = {
      id: '',
      aliases: '',
    }
  },
  SET_EXTEND(state, payload) {
    state.extend = payload
  },
  SET_AUTHORIZATION(state, payload) {
    state.authorization = payload
  },
}

const actions = {
  getmenu({ commit }) {
    return new Promise((resolve, reject) => {
      $axios.get(`/get-menu`)
        .then((response) => {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            commit('SET_EXTEND', response.data)
            resolve(response.status)
          }
        })
    })
  },
  getauthorization({ commit }) {
    return new Promise((resolve, reject) => {
      $axios.get(`/get-authorization`)
        .then((response) => {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            commit('SET_AUTHORIZATION', response.data)
            resolve(response.status)
          }
        })
    })
  },
  Index({ commit, state }) {
    commit('SET_LOADING', true, { root: true })
    return new Promise((resolve) => {
      const { page, itemsPerPage } = state.options;
      $axios
        .get(`/shipmaster?initials=recycle&page=${page}&perpage=${itemsPerPage}&search=${state.search}`)
        .then(function (response) {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            const setdata = {
              desserts: response.data.data,
              totalDesserts: response.data.meta.total
            };
            commit('SET_DATATABLES', setdata)
            commit('SET_LOADING', false, { root: true })
            resolve(response.status)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  },
  createfromCore({ dispatch, commit }, payload) {
    return new Promise((resolve) => {
      $axios.post(`/shipmaster/`, payload)
        .then(function (response) {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            dispatch('Index')
            resolve(response.status)
            commit('SET_ALERTBANNER', response.data, { root: true })
            setTimeout(() => {
              commit('CLEAR_ALERTBANNER', response.data, { root: true })
            }, 3000);
          }
        })
        .catch(function (error) {
          if (error.response) {
            commit('SET_ERRORS', error.response.data.errors, { root: true })
            resolve(error.response.status)
          }
        });
    })
  },
  restored({ dispatch, commit }, payload) {
    return new Promise((resolve) => {
      $axios.post(`/shipmaster/multirestored`, payload)
        .then((response) => {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            dispatch('Index')
            resolve(response.status)
            commit('SET_ALERTBANNER', response.data, { root: true })
            setTimeout(() => {
              commit('CLEAR_ALERTBANNER', response.data, { root: true })
            }, 3000);
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status)
          }
        });
    })
  },
  forcedelete({ dispatch, commit }, payload) {
    return new Promise((resolve) => {
      $axios.post(`/shipmaster/multi-force-destroy`, payload)
        .then((response) => {
          if (response.status === 200 && response.data.status === "Token is Expired") {
            setTimeout(() => {
              localStorage.setItem('token', null)
              commit('SET_TOKEN', null, { root: true })
            }, 1000)
            resolve(401)
          } else {
            dispatch('Index')
            resolve(response.status)
            commit('SET_ALERTBANNER', response.data, { root: true })
            setTimeout(() => {
              commit('CLEAR_ALERTBANNER', response.data, { root: true })
            }, 3000);
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status)
          }
        });
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}

