<template>
  <v-card class="rounded-lg card" flat>
    <v-card-title>
      Users Manage
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      dense
      v-model="selected"
      :single-select="select"
      item-key="id"
      show-select
      :headers="headers"
      :items="desserts"
      :search="search"
      :options.sync="options"
      :server-items-length="totalDesserts"
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-switch
            v-model="select"
            label="Single select"
            class="pa-3 mt-7"
          ></v-switch>
          <v-btn
            class="mx-2"
            fab
            small
            @click="create()"
            v-if="$can('user-create')"
            color="primary"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
          <v-btn
            class="mx-2"
            fab
            small
            @click="recycleBin()"
            v-if="$can('user-delete')"
            color="secondary"
          >
            <v-icon> mdi-delete-variant  </v-icon>
          </v-btn>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn
            class="mx-2"
            fab
            small
            v-if="selected.length > 0 && $can('user-delete')"
            @click="deleted()"
            color="error"
          >
            <v-icon> mdi-delete </v-icon>
          </v-btn>
          <v-spacer />
          <app-alert />
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete = false">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogHistory">
            <v-card>
              <v-card-title class="text-h5">History</v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="headersHistory"
                  :items="dessertsHistory"
                  :options.sync="optionsHistory"
                  :server-items-length="totalDessertsHistory"
                  :loading="loadingHistory"
                  class="elevation-1"
                >
                  <template v-slot:[`item.model_type`]="{ item }">
                    <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.model_type }}</p>
                  </template>
                  <template v-slot:[`item.message`]="{ item }">
                    <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.message }}</p>
                  </template>
                  <template v-slot:[`item.meta`]="{ item }">
                    <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.meta }}</p>
                  </template>
                  <template v-slot:[`item.performed_at`]="{ item }">
                    <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.performed_at }}</p>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-btn color="blue darken-1" text @click="dialogHistory = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.name }}</p>
      </template>
      <template v-slot:[`item.email`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.email }}</p>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.created_at }}</p>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <v-btn x-small outlined dense
          :color="item.type === 'front-office' ? 'indigo' : 'green'"
        >
          {{ item.type }}
        </v-btn>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="History(item.id)" v-if="$can('user-edit')">
          mdi-history
        </v-icon>
        <v-icon small class="mr-2" @click="Edit(item.id)" v-if="$can('user-edit')">
          mdi-chevron-double-right
        </v-icon>
      </template>
    </v-data-table>
    <v-divider class="mx-4" inset horizontal></v-divider>
    <app-recycle-bin v-if="recycleBins === true"/>
  </v-card>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Alert from "../../components/Alert.vue";
import RecycleBins from './RecycleBins';
export default {
  components: {
    "app-alert": Alert,
    "app-recycle-bin": RecycleBins,
  },
  data() {
    return {
      recycleBins: false,
      selected: [],
      select: false,
      dialogDelete: false,
      dialogHistory: false,

      // state history
      idHistory: '',
      totalDessertsHistory: 0,
      dessertsHistory: [],
      loadingHistory: true,
      optionsHistory: {},
      headersHistory: [
          {
          text: 'Models (type)',
          align: 'start',
          sortable: false,
          value: 'model_type',
        },
        { text: 'Message', value: 'message' },
        { text: 'Meta (data)', value: 'meta' },
        { text: 'Performed at', value: 'performed_at' },
      ],
    };
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState("user", {
      totalDesserts: (state) => state.totalDesserts,
      desserts: (state) => state.desserts,
      search: (state) => state.search,
      options: (state) => state.options,
      headers: (state) => state.headers,
    }),
    options: {
      get: function () {
        return this.$store.state.user.options;
      },
      set: function (value) {
        this.$store.commit("user/SET_OPTIONS", value);
      },
    },
    search: {
      get: function () {
        return this.$store.state.user.search;
      },
      set: function (value) {
        this.$store.commit("user/SET_SEARCH", value);
      },
    },
    loading: {
      get: function () {
        return this.$store.state.loading;
      },
      set: function (value) {
        this.$store.commit("SET_LOADING", value);
      },
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    search() {
      this.getDataFromApi();
    },
    optionsHistory: {
      handler () {
        this.History(this.idHistory)
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    ...mapActions("user", ["Index", "getroles", "show", "delete","getHistory"]),
    ...mapActions("auth", ["logout"]),
    getDataFromApi() {
      this.Index().then((e)=>{
        if (e != 200) {
          this.logout().then((r) => {
            this.$router.push({ name: 'auth.login' }) 
          })
        }
      });
    },
    create() {
      this.getroles().then((e)=>{
        if (e != 200) {
          this.logout().then((r) => {
            this.$router.push({ name: 'auth.login' }) 
          })
        }
      });
      this.$store.commit("user/SET_DIALOG", { show: true });
    },
    Edit(e) {
      this.getroles().then((r)=>{
        if (r != 200) {
          this.logout().then((s) => {
            this.$router.push({ name: 'auth.login' }) 
          })
        }
      });
      this.show(e).then((x)=>{
        if (x != 200) {
          this.logout().then((y) => {
            this.$router.push({ name: 'auth.login' }) 
          })
        }
      });
      this.$store.commit("user/SET_DIALOG", { show: true, id: e });
    },
    History(e){
      this.idHistory=e
      this.dialogHistory=true
      this.loadingHistory = true
      const { page } = this.optionsHistory
      this.getHistory({pages:page,id:this.idHistory}).then((res)=>{
        this.dessertsHistory = res.data
        this.totalDessertsHistory = res.meta.total
        this.loadingHistory = false
      });
    },
    deleted(){
      this.dialogDelete=true
    },
    deleteConfirm() {
      this.delete(this.selected).then((e) => {
        if(e === 200){
          this.dialogDelete=false
        }
      })
    },
    recycleBin(){
      this.recycleBins=!this.recycleBins;
    }
  },
};
</script>
