<template>
    <v-card>
        <v-toolbar>
            <v-select :items="itemsKuartal" label="Kuartal" item-text="latin" item-value="no" class="mt-8 mr-3"
                v-model="form.kuartal"></v-select>
            <v-divider vertical></v-divider>
            <v-select :items="itemsTahun" label="Tahun" class="mt-8 mr-3 ml-3" v-model="form.tahun"></v-select>
            <v-divider vertical></v-divider>
            <v-progress-circular indeterminate color="primary" class="mx-2" v-if="loading"></v-progress-circular>
            <v-btn class="mx-2 mt-1" fab dark x-small color="primary" @click="submit" v-else>
                <v-icon dark>
                    mdi-send
                </v-icon>
            </v-btn>
            <v-progress-circular indeterminate color="secondary" class="mx-2" v-if="loadingpdf"></v-progress-circular>
            <v-btn class="mx-2 mt-1" fab dark x-small color="secondary" @click="pdf" v-else>
                <v-icon dark>
                    mdi-pdf-box
                </v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text v-if="(desserts.length !== 0)">
            <div v-for="item in desserts" :key="item[0].cust_accnt">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th colspan="2">{{ item[0].custmr }}</th>
                            </tr>
                            <tr>
                                <th class="text-left">
                                    PRODUCT
                                </th>
                                <th class="text-left">
                                    TARGET
                                </th>
                                <th class="text-left">
                                    OKTOBER
                                </th>
                                <th class="text-left">
                                    NOVEMBER
                                </th>
                                <th class="text-left">
                                    DESEMBER
                                </th>
                                <th class="text-left">
                                    FO
                                </th>
                                <th class="text-left">
                                    %TGT-ACH
                                </th>
                                <th class="text-left">
                                    %
                                </th>
                                <th class="text-left">
                                    INCENTIVE
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="x in item" :key="x.PRODUCTGROUP" :class="color(x.kelompok_produk)">
                                <td v-bind:class="[
                                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                                ]" class="text-left">{{ x.kelompok_produk }}</td>
                                <td v-bind:class="[
                                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                                ]" class="text-right">{{ currency(parseFloat(x.target)) }}</td>
                                <td v-bind:class="[
                                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                                ]" class="text-right">{{ currency(parseFloat(x.total_amount_first)) }}</td>
                                <td v-bind:class="[
                                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                                ]" class="text-right">{{ currency(parseFloat(x.total_amount_second)) }}</td>
                                <td v-bind:class="[
                                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                                ]" class="text-right">{{ currency(parseFloat(x.total_amount_thirth)) }}</td>
                                <td v-bind:class="[
                                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                                ]" class="text-right">{{ currency(parseFloat(x.fo)) }}</td>
                                <td v-bind:class="[
                                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                                ]" class="text-right">{{ x.tgt_ach + '%' }}</td>
                                <td v-bind:class="[
                                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                                ]" class="text-right">{{ x.incent + '%' }}</td>
                                <td v-bind:class="[
                                    $vuetify.theme.dark ? 'text-light' : 'text-dark',
                                ]" class="text-right">{{ currency(x.incentive) }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-divider></v-divider>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
    data: () => ({
        itemsKuartal: [
            { no: 1, latin: 'Januari-Maret', value: '01-03' },
            { no: 2, latin: 'April-Juni', value: '04-06' },
            { no: 3, latin: 'Juli-September', value: '07-09' },
            { no: 4, latin: 'Oktober-Desember', value: '10-12' },
        ],
        itemsTahun: [],
        form: {
            kuartal: '',
            tahun: ''
        },
        loading: false,
        loadingpdf: false
    }),
    computed: {
        ...mapState("report_bonus_dealer", {
            desserts: (state) => state.desserts,
        }),
    },
    mounted() {
        this.setYears()
    },
    methods: {
        ...mapActions("report_bonus_dealer", ["Index", "printpdf"]),
        setYears() {
            var max = new Date().getFullYear();
            var min = max - 9;
            var years = this.itemsTahun;
            for (var i = max; i >= min; i--) {
                years.push(i);
            }
        },
        submit() {
            this.loading = true;
            this.Index(this.form).then((res) => {
                this.loading = false;
            })
        },
        currency(angka) {
            return parseFloat(angka)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        color(e) {
            if (e === 'TOTAL') {
                return this.$vuetify.theme.dark ? 'primary' : 'info'
            }
        },
        pdf() {
            this.loadingpdf = true;
            this.printpdf(this.form).then((res) => {
                const file = new Blob([res.data], { type: "application/pdf" });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                console.log(res);
                this.loadingpdf = false;
            })
        }
    }
}
</script>