var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"options":_vm.options,"server-items-length":_vm.totalDesserts,"loading":_vm.loadingTable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"card",attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Search","placeholder":"Find by...","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v("\n        "+_vm._s(new Date(item.updated_at).toUTCString())+"\n      ")])]}},{key:"item.no_order",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v("\n        "+_vm._s(item.no_order)+"\n      ")])]}},{key:"item.periode",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v("\n        "+_vm._s(item.periode)+"\n      ")])]}},{key:"item.shifto",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v("\n        "+_vm._s(item.shifto)+"\n      ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v("\n        "+_vm._s(item.type)+"\n      ")])]}},{key:"item.files",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v("\n        "+_vm._s(item.files)+"\n      ")])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":(item.state === 'undownloaded') ? 'red' : 'green',"dark":""}},[_vm._v("\n        "+_vm._s(item.state)+"\n      ")])]}},{key:"item.act",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.show(item.no_order)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-database-arrow-right")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Show data file")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","x-small":"","loading":_vm.loadingDownload},on:{"click":function($event){return _vm.downloaded(item.no_order)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Download data file .xlsx")])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }