var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headersShipTo,"items":_vm.dessertsShipTo,"options":_vm.optionsShipTo,"server-items-length":_vm.totalDessertsShipTo,"loading":_vm.loadingShipTo,"single-select":_vm.singleSelectShipTo,"item-key":"id","show-select":""},on:{"update:options":function($event){_vm.optionsShipTo=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-switch',{staticClass:"pa-3",attrs:{"label":"Single select"},model:{value:(_vm.singleSelectShipTo),callback:function ($$v) {_vm.singleSelectShipTo=$$v},expression:"singleSelectShipTo"}})]},proxy:true},{key:"item.sales_district_id",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v(_vm._s(item.sales_district_id))])]}},{key:"item.custgroup",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v(_vm._s(item.custgroup))])]}},{key:"item.district",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v(_vm._s(item.district))])]}},{key:"item.accountnum",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v(_vm._s(item.accountnum))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v(_vm._s(item.name))])]}},{key:"item.aliases",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v(_vm._s(item.aliases))])]}}],null,true),model:{value:(_vm.form.shipto),callback:function ($$v) {_vm.$set(_vm.form, "shipto", $$v)},expression:"form.shipto"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }