var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"options":_vm.options,"server-items-length":_vm.totalDesserts,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v("\n        "+_vm._s(item.name)+"\n      ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v("\n        "+_vm._s(item.email)+"\n      ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-3",class:[_vm.$vuetify.theme.dark ? 'text-light' : 'text-dark']},[_vm._v("\n        "+_vm._s(item.created_at)+"\n      ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","outlined":"","dense":"","color":item.type === 'front-office' ? 'indigo' : 'green'}},[_vm._v("\n        "+_vm._s(item.type)+"\n      ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$can('user-delete'))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.restore(item.id)}}},[_vm._v("\n        mdi-restore\n      ")]):_vm._e(),_vm._v(" "),(_vm.$can('user-delete'))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.forceDelete(item.id)}}},[_vm._v("\n        mdi-delete-alert\n      ")]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }