import $axios from '../../core/api.js'

const state = () => ({
    search: '',
    loadingTable: false,
    totalDesserts: 0,
    desserts: [],
    options: {},
    headers: [
        {
            text: "NO ORDER",
            align: "start",
            sortable: false,
            value: "no_order",
        },
        { text: "POST", value: "updated_at" },
        { text: "PERIODE", value: "periode" },
        { text: "SHIPTO", value: "shifto" },
        // { text: "TYPE", value: "type" },
        { text: "STATUS", value: "state" },
        // { text: "FILES", value: "files" },
        { text: 'ACTION', value: 'act', sortable: false },
    ],

    searchDetailsRO: '',
    loadingTableDetailsRO: false,
    totalDessertsDetailsRO: 0,
    dessertsDetailsRO: [],
    expandedDetailsRO: [],
    singleExpandDetailsRO: false,
    optionsDetailsRO: {},
    headersDetailsRO: [
        {
            text: "ITEM NUMBER",
            align: "start",
            sortable: false,
            value: "item_id",
        },
        { text: "DESCRIPTION", value: "desc" },
        { text: "QTY/BOX", value: "box_qty" },
        { text: "MULTI-QTY", value: "mtp_qty" },
        { text: "PRICE", value: "price" },
        { text: "DISC", value: "disc" },
        { text: "QTY", value: "qty" },
        { text: "AMOUNT", value: "amount" },
        { text: '', value: 'data-table-expand' },
    ],
})

const mutations = {
    SET_SEARCH(state, payload) {
        state.search = payload
    },
    SET_LOADING(state, payload) {
        state.loadingTable = payload
    },
    SET_DATATABLES(state, payload) {
        state.totalDesserts = payload.totalDesserts,
            state.desserts = payload.desserts
    },
    SET_OPTIONS(state, payload) {
        state.options = payload
    },
    // ==============================
    SET_SEARCH_DETAIL_RO(state, payload) {
        state.searchDetailsRO = payload
    },
    SET_LOADING_DETAIL_RO(state, payload) {
        state.loadingTableDetailRO = payload
    },
    SET_DATATABLES_DETAIL_RO(state, payload) {
        state.dessertsDetailsRO = payload
    },
    SET_DATATABLES_DETAIL_RO_TOTAL(state, payload) {
        state.totalDessertsDetailsRO = payload
    },
    SET_OPTIONS_DETAIL_RO(state, payload) {
        state.optionsDetailsRO = payload
    },
    EXPANDED_DETAILS_RO(state, payload) {
        state.expandedDetailsRO = payload
    },
    SINGLEXPAND_DETAILS_RO(state, payload) {
        state.singleExpandDetailsRO = payload
    },
}

const actions = {
    Index({ commit, state }) {
        commit('SET_LOADING', true)
        return new Promise((resolve) => {
            const { page, itemsPerPage } = state.options;
            $axios
                .get(`/order-list-ro?page=${page}
            &perpage=${itemsPerPage}
            &search=${state.search}
            &data=${state.pick}
            `)
                .then(function (response) {
                    if (response.status === 200 && response.data.status === "Token is Expired") {
                        setTimeout(() => {
                            localStorage.setItem('token', null)
                            commit('SET_TOKEN', null, { root: true })
                        }, 1000)
                        resolve(401)
                    } else {
                        const setdata = {
                            desserts: response.data.data,
                            totalDesserts: response.data.meta.total
                        };
                        commit('SET_DATATABLES', setdata)
                        commit('SET_LOADING', false)
                        resolve(response.status)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    },
    // ================================DETAIL======================================
    IndexDetailRO({ commit, state }, payload) {
        commit('SET_LOADING_DETAIL_RO', true)
        return new Promise((resolve) => {
            const { page, itemsPerPage } = state.optionsDetailsRO;
            $axios
                .get(`/order-show-client/${payload}?page=${page}
                &perpage=${itemsPerPage}
                &search=${state.searchDetailsRO}
                &data=ro
                `)
                .then(function (response) {
                    if (response.status === 200 && response.data.status === "Token is Expired") {
                        setTimeout(() => {
                            localStorage.setItem('token', null)
                            commit('SET_TOKEN', null, { root: true })
                        }, 1000)
                        resolve(401)
                    } else {
                        commit('SET_DATATABLES_DETAIL_RO', response.data.data)
                        commit('SET_DATATABLES_DETAIL_RO_TOTAL', response.data.total)
                        commit('SET_LOADING_DETAIL_RO', false)
                        resolve(response.status)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    },
    orderNotification({ commit }) {
        return new Promise((resolve) => {
            $axios.get(`get-count-notif`)
            .then((response) => {
                if (response.status === 200 && response.data.status === "Token is Expired") {
                    setTimeout(() => {
                        localStorage.setItem('token', null)
                        commit('SET_TOKEN', null, { root: true })
                    }, 1000)
                    resolve(401)
                } else {
                    resolve(response.data)
                    commit('SET_ORDER_NOTIF', response.data, { root: true })
                }
            })
        })
    },
    download({ dispatch }, payload) {
        return new Promise((resolve) => {
            $axios
                .get(`/download-file/${payload}`,{responseType: 'blob'})
                .then(function (response) {
                    if (response.status === 200 && response.data.status === "Token is Expired") {
                        setTimeout(() => {
                            localStorage.setItem('token', null)
                            commit('SET_TOKEN', null, { root: true })
                        }, 1000)
                        resolve(401)
                    } else {
                        dispatch('Index')
                        dispatch('orderNotification')
                        resolve(response)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}

