<template>
  <v-container>
    <v-row class="d-flex justify-content-end px-3 mt-3">
      <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href:
          window.location.protocol + "//" + window.location.hostname + ":8000/",
      },
      {
        text: "Bonus Dealers",
        disabled: false,
        href:
          window.location.protocol +
          "//" +
          window.location.hostname +
          ":8000/report-bonus-dealer",
      },
      {
        text: "Notification Confirm",
        disabled: false,
        href:
          window.location.protocol +
          "//" +
          window.location.hostname +
          ":8000/notification-b-d",
      },
    ],
  }),
  computed: {
    ...mapGetters(["isAuth"]),
    ...mapState(["baseUrl"]),
  },
};
</script>