<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog_medium.show"
      persistent
      max-width="390"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ dialog_medium.message }}
        </v-card-title>
        <v-card-text>
          To ensure that all registered accounts have an active email address, we will hereby check your email activation. We will also delete all accounts including yours if you do not agree to this verification request. our best regards and thank you for your attention.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="disagre"
          >
            Disagree
          </v-btn>
          <v-btn
            text
            @click="agree"
          >
            Agree request verfy
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions,mapState } from 'vuex'
export default {
    computed: {
        ...mapState(['dialog_medium']),
        dialog_medium: {
            get(){
                return this.$store.state.dialog_medium
            },
            set(v){
                return v
            } 
        }
    },
    methods: {
        ...mapActions('auth', ['sendEmailVerifySocialSignIn','UnsendEmailVerifySocialSignIn']),
        disagre(){
          this.UnsendEmailVerifySocialSignIn()
        },
        agree(){
          this.sendEmailVerifySocialSignIn()
        }
    }
}
</script>