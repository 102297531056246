import Vue from 'vue'
import Vuex from 'vuex'

// store module
import auth from '../store/auth.js'
import user from '../store/users.js'
import role from '../store/roles.js'
import menu from '../store/menus.js'
import promotion from '../store/promotion'
import permissions from '../store/permissions.js'
import dashboard from '../store/dashboard.js'
import shipto from '../store/shipto/shipto.js'
import shiptoCore from '../store/shipto/shiptoCore.js'
import shiptoRecycleBin from '../store/shipto/shiptoRecycleBin.js'
import listfo from '../store/OrderList/ListFO.js'
import listro from '../store/OrderList/ListRO.js'
import quartals from '../store/bonusDealers/quartals.js'
import report_bonus_dealer from '../store/bonusDealers/report'
import notif_bonus_dealer from '../store/bonusDealers/notif.js'
// store module
Vue.use(Vuex)
const store = new Vuex.Store({
    modules: {
        auth,
        dashboard,
        user,
        role,
        menu,
        promotion,
        permissions,
        shipto,
        shiptoCore,
        shiptoRecycleBin,
        listfo,
        listro,
        quartals,
        report_bonus_dealer,
        notif_bonus_dealer
    },
    state: {
        token: localStorage.getItem('token'),
        token_expired: false,
        baseUrl: window.location.protocol + '//' + window.location.hostname,
        errors: [],
        drawer: true,
        loading: false,
        alertbanner: {
            alert: false,
            status: '',
            message: ''
        },
        snackbar: {
            snackbar_show: false,
            text: ''
        },
        dialog_medium: {
            show: false,
            message: ''
        },
        stepauth:1,
        orderNotif:0
    },
    getters: {
        isAuth: state => {
            return state.token != "null" && state.token != null
        }
    },
    mutations: {
        SET_ORDER_NOTIF(state, payload) {
            state.orderNotif = payload
        },
        SET_DIALOG_MEDIUM(state, payload) {
            state.dialog_medium = {
                show: true,
                message: payload.message,
            }
        },
        CLEAR_DIALOG_MEDIUM(state, payload) {
            state.dialog_medium = {
                show: false,
                message: '',
            }
        },
        SET_ALERTBANNER(state, payload) {
            state.alertbanner = {
                alert: true,
                status: payload.status,
                message: payload.message,
            }
        },
        CLEAR_ALERTBANNER(state, payload) {
            state.alertbanner = {
                alert: false,
                status: '',
                message: '',
            }
        },
        SET_SNACKBAR(state, payload) {
            state.snackbar = {
                snackbar_show: true,
                text: payload.message,
            }
        },
        CLEAR_SNACKBAR(state, payload) {
            state.snackbar = {
                snackbar_show: false,
                text: '',
            }
        },
        SET_LOADING(state, payload) {
            state.loading = payload
        },
        SET_DRAWER(state, payload) {
            state.drawer = payload
        },
        SET_TOKEN(state, payload) {
            state.token = payload
        },
        SET_TOKEN_EXPIRED(state, payload) {
            state.token_expired = payload
        },
        SET_ERRORS(state, payload) {
            state.errors = payload
        },
        CLEAR_ERRORS(state) {
            state.errors = []
        },
        SET_STEPAUTH(state, payload) {
            state.stepauth = payload
        },
    }
})
export default store
