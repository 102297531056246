<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-center">
        <p>PT. CAPELLA PATRIA UTAMA-PKB</p>
      </v-card-title>
      <v-card-text v-if="loading" class="text-center">
        <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
      </v-card-text>
      <v-card-text>
        <!-- expansion panel::START -->
        <v-expansion-panels>
          <v-expansion-panel v-for="(item, i) in desserts_edit" :key="i">
            <v-expansion-panel-header v-text="item.no_order" />
            <v-expansion-panel-content>
              <!-- table-contents::START -->
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">PRODUCT GROUP</th>
                      <th class="text-center">TARGET</th>
                      <th class="text-center">OKTOBER</th>
                      <th class="text-center">NOVEMBER</th>
                      <th class="text-center">DESEMBER</th>
                      <th class="text-center">TOTAL FO Q4 2020</th>
                      <th class="text-center">%TGT-ACH%</th>
                      <th class="text-center">%INCENT</th>
                      <th class="text-center">INCENTIVE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="items in item.data"
                      :key="items.kelompok_produk"
                      :class="
                        getColor(items.kelompok_produk, $vuetify.theme.dark)
                      "
                    >
                      <td
                        v-bind:class="[
                          $vuetify.theme.dark ? 'text-light' : 'text-dark',
                        ]"
                      >
                        {{ items.kelompok_produk }}
                      </td>
                      <td
                        v-bind:class="[
                          $vuetify.theme.dark ? 'text-light' : 'text-dark',
                        ]"
                      >
                        {{
                          parseFloat(items.target)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}
                      </td>
                      <td
                        v-bind:class="[
                          $vuetify.theme.dark ? 'text-light' : 'text-dark',
                        ]"
                      >
                        <v-text-field dense v-model="items.qty_1.qty" type="number" @change="changeValue({
                          'id':items.qty_1.id, 
                          'qty':items.qty_1.qty,
                          'field':'qty_1',
                          'id_sfo':items.id_sfo
                          })">
                          <v-icon slot="append" color="red" small>
                            mdi-plus
                          </v-icon>
                          <v-icon slot="append" color="green" small>
                            mdi-minus
                          </v-icon>
                        </v-text-field>
                      </td>
                      <td
                        v-bind:class="[
                          $vuetify.theme.dark ? 'text-light' : 'text-dark',
                        ]"
                      >
                        <v-text-field dense v-model="items.qty_2.qty" type="number" @change="changeValue({
                          'id':items.qty_2.id, 
                          'qty':items.qty_2.qty,
                          'field':'qty_2',
                          'id_sfo':items.id_sfo
                          })">
                          <v-icon slot="append" color="red" small>
                            mdi-plus
                          </v-icon>
                          <v-icon slot="append" color="green" small>
                            mdi-minus
                          </v-icon>
                        </v-text-field>
                      </td>
                      <td
                        v-bind:class="[
                          $vuetify.theme.dark ? 'text-light' : 'text-dark',
                        ]"
                      >
                        <v-text-field dense v-model="items.qty_3.qty" type="number" @change="changeValue({
                          'id':items.qty_3.id, 
                          'qty':items.qty_3.qty,
                          'field':'qty_3',
                          'id_sfo':items.id_sfo
                          })">
                          <v-icon slot="append" color="red" small>
                            mdi-plus
                          </v-icon>
                          <v-icon slot="append" color="green" small>
                            mdi-minus
                          </v-icon>
                        </v-text-field>
                      </td>
                      <td
                        v-bind:class="[
                          $vuetify.theme.dark ? 'text-light' : 'text-dark',
                        ]"
                      >
                        {{
                          parseFloat(items.fo)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}
                      </td>
                      <td
                        v-bind:class="[
                          $vuetify.theme.dark ? 'text-light' : 'text-dark',
                        ]"
                      >
                        {{ items.tgt_ach }} %
                      </td>
                      <td
                        v-bind:class="[
                          $vuetify.theme.dark ? 'text-light' : 'text-dark',
                        ]"
                      >
                        {{ items.incent }} %
                      </td>
                      <td
                        v-bind:class="[
                          $vuetify.theme.dark ? 'text-light' : 'text-dark',
                        ]"
                      >
                        {{
                          parseFloat(items.incentive)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <!-- table-contents::END -->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- expansion panel::END -->
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState("quartals", {
      desserts_edit: (state) => state.desserts_edit,
    }),
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    ...mapActions("quartals", ["Edit","Updated"]),
    ...mapActions("auth", ["logout"]),
    getColor(kel_prod, theme) {
      if (kel_prod === "INDOPARTS") {
        if (theme) return "blue darken-4";
        else return "light-blue accent-1";
      } else if (kel_prod === "INDOBATTERY") {
        if (theme) return "brown darken-3";
        else return "cyan accent-1";
      } else if (kel_prod === "TYRES") {
        if (theme) return "blue-grey darken-3";
        else return "teal accent-1";
      }
    },
    getDataFromApi() {
      this.loading = true;
      const params = {
        dealers: this.$route.params.dealer,
        quartals: this.$route.params.quartals,
        year: this.$route.params.years,
      };
      this.Edit(params).then((e) => {
        this.loading = false;
        if (e != 200) {
          this.logout().then((r) => {
            this.$router.push({ name: "auth.login" });
          });
        }
      });
    },
    changeValue(arr){
      var params = [
          {
            dealers: this.$route.params.dealer,
            quartals: this.$route.params.quartals,
            year: this.$route.params.years,
          }
      ];
      params.push(arr);
      this.Updated(params).then((e) => {
        if (e != 200) {
          this.logout().then((r) => {
            this.$router.push({ name: "auth.login" });
          });
        }
      });
    }
  },
};
</script>
