<template>
  <v-app-bar flat app class="appbar">
    <v-app-bar-nav-icon @click="drawerFunction(drawer)">
      <v-icon>
        {{ drawer === true ? "mdi-forwardburger" : "mdi-backburger" }}
      </v-icon>
    </v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <v-toolbar-title>{{ $route.meta.title }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-switch
      v-model="themes"
      inset
      persistent-hint
      class="mt-6 ml-6"
    ></v-switch>
    <v-chip class="ma-2" color="primary" outlined pill>
      {{ authenticated.name }}
      <v-icon right> mdi-account-outline </v-icon>
    </v-chip>
    <v-menu left bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img
              :src="baseUrl + '/storage/img/User Avatar_Two Color.svg'"
            ></v-img>
          </v-list-item-avatar>

          <v-list-item-title>{{ authenticated.name }}</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item v-for="n in option" :key="n.link" @click="() => {}">
          <v-icon>{{ n.icon }}</v-icon>
          <v-list-item-title @click="click(n.link)">{{
            n.text
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  data: () => ({
    option: [
      {
        text: "Account",
        link: "account",
        icon: "mdi-clipboard-account",
      },
      {
        text: "History",
        link: "history",
        icon: "mdi-timeline",
      },
      {
        text: "Logout",
        link: "logout",
        icon: "mdi-logout-variant",
      },
    ],
    themes: true,
  }),
  created() {
    this.getMenu();
  },
  computed: {
    ...mapGetters(["isAuth"]),
    ...mapState(["baseUrl"]),
    ...mapState("auth", {
      authenticated: (state) => state.authenticated,
    }),
    ...mapState({
      drawer: (state) => state.drawer,
    }),
  },
  watch: {
    themes() {
      this.themesfunction();
    },
  },
  mounted() {
    const theme = localStorage.getItem("theme");
    if (theme) {
      if (theme == "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    }
  },
  methods: {
    ...mapActions("auth", ["getMenu", "logout"]),
    drawerFunction(e) {
      let setDrawer = e === true ? false : true;
      this.$store.commit("SET_DRAWER", setDrawer);
    },
    click(e) {
      if (e === "account") {
        this.$router.push({ name: "account" });
      } else if (e === "history") {
        this.$router.push({ name: "history" });
      } else if (e === "logout") {
        this.logout().then((e) => {
          if (e === 200) {
            this.$router.push({ name: "auth.login" });
          }
        });
      }
    },
    themesfunction() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("theme", this.$vuetify.theme.dark.toString());
    },
  },
};
</script>