<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{
            id ? "Update permissions" : "Create permissions"
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12" :md="form.type === 'sub-permissions' ? 4 : 6">
                    <v-text-field v-model="form.name" label="Permission Name" />
                  </v-col>
                  <v-col cols="12" :md="form.guard_name === 'sub-permissions' ? 4 : 6">
                    <v-text-field v-model="form.guard_name" label="Permission Guard" />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="id ? update(id) : create()">
            {{ id ? "Updated" : "Created" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState(["loading", "errors"]),
    ...mapState("permissions", {
      dialog: (state) => state.dialog.show,
      id: (state) => state.dialog.id,
      form: (state) => state.form,
    }),
    dialog: {
      get: function () {
        return this.$store.state.permissions.dialog.show;
      },
      set: function (value) {
        this.$store.commit("permissions/SET_DIALOG", { show: value });
      },
    },
    id: {
      get: function () {
        return this.$store.state.permissions.dialog.id;
      },
      set: function (value) {
        this.$store.commit("permissions/SET_DIALOG", { id: value });
      },
    },
  },
  watch: {
    dialog(n) {
      if (n === false) {
        this.$store.commit("permissions/CLEAR_FORM");
      }
    },
  },
  methods: {
    ...mapActions("permissions", ["createOrUpdate"]),
    create() {
      this.createOrUpdate().then((response) => {
        if (response === 200) {
          this.$store.commit("permissions/SET_DIALOG", { show: false });
        }
      });
    },
    update(id) {
      this.createOrUpdate(id).then((response) => {
        if (response === 200) {
          this.$store.commit("permissions/SET_DIALOG", { show: false });
        }
      });
    },
  },
};
</script>