<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-card class="rounded-lg card" flat>
        <app-data-masters-table />
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card class="rounded-lg card" flat>
        <app-data-code-table />
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import DataMasters from "./DataMasters";
import DataCore from "./DataCore";
export default {
  components: {
    "app-data-masters-table": DataMasters,
    "app-data-code-table": DataCore,
  },
};
</script>
