<template>
  <v-navigation-drawer
    class="drawer"
    floating
    app
    :mini-variant.sync="drawer"
    permanent
    mini-variant-width="100"
  >
    <!-- list menu -->
    <v-list>
      <v-list-item>
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="/storage/img/logo.png"
          transition="scale-transition"
          width="200"
        />
      </v-list-item>
    </v-list>
    <v-divider dark></v-divider>
    <v-list nav dense rounded dark>
      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- menu -->
        <v-tooltip v-for="(item, i) in menu" :key="i" right>
          <span>{{ item.name }}</span>
          <template v-slot:activator="{ on, attrs }">
            <v-list-group
              v-bind="attrs"
              v-on="on"
              :value="false"
              :prepend-icon="item.icon"
              v-if="$can(item.authorization)"
            >
              <template v-slot:activator>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </template>
              <v-tooltip v-for="(sub, i) in item.extend" :key="i" right>
                <span>{{ sub.name }}</span>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item
                    v-if="$can(sub.authorization)"
                    :to="sub.link"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ sub.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ sub.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action
                      v-if="sub.link === 'orderlist' && orderNotif > 0"
                    >
                      <v-badge color="green" :content="orderNotif" />
                    </v-list-item-action>
                    <v-list-item-action v-if="sub.link === '/notification-b-d' && notifbonusdealer > 0">
                      <v-badge color="green" :content="notifbonusdealer" />
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-tooltip>
            </v-list-group>
          </template>
        </v-tooltip>
        <!-- menu -->
      </v-list-item-group>
    </v-list>
    <!-- list menu -->
  </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      selectedItem: 1,
      mini: true,
    };
  },
  computed: {
    ...mapGetters(["isAuth"]),
    ...mapState(["drawer", "baseUrl"]),
    ...mapState("auth", {
      menu: (state) => state.menu,
      authenticated: (state) => state.authenticated,
      orderNotif: (state) => state.orderNotif,
    }),
    ...mapState("notif_bonus_dealer", {
      notifbonusdealer: (state) => state.notif,
    }),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(v) {
        this.$store.commit("SET_DRAWER", v);
      },
    },
    orderNotif: {
      get() {
        return this.$store.state.orderNotif;
      },
      set(v) {
        this.$store.commit("SET_ORDER_NOTIF", v);
      },
    },
    notifbonusdealer: {
      get() {
        return this.$store.state.notif_bonus_dealer.notif;
      },
      set(v) {
        this.$store.commit("notif_bonus_dealer/SET_NOTIF", v);
      },
    },
  },
  created() {
    this.orderNotification();
    this.Notification();
  },
  methods: {
    ...mapActions("auth", ["orderNotification"]),
    ...mapActions("notif_bonus_dealer", ["Notification"]),
  },
};
</script>