<template>
  <v-container class="page-login" fill-height>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-6 rounded-xl mt-8" elevation="7">
          <v-card-text>
            <v-row>
              <v-col
                md="8"
                sm="12"
                class="pa-15 pr-10 d-flex justify-content-center"
                v-if="$vuetify.breakpoint.mdAndUp"
              >
                <img
                  :src="baseUrl + '/storage/img/app_development_SVG.svg'"
                  alt="Vue Material Admin"
                  width="50%"
                  contain
                />
              </v-col>
              <v-col md="4" sm="12" color="indigo darken-4">
                <v-row class="d-flex justify-center">
                  <div class="primary--text display-1">Core Indoparts</div>
                </v-row>
                <router-view></router-view>
                <alert-page />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    "alert-page": Alert,
  },
  computed: {
    ...mapState(["baseUrl"]),
  },
};
</script>

<style lang="sass" scoped>
.page-login
  margin: 0 auto
</style>
