<template>
  <v-container>
    <v-row class="d-flex justify-content-between px-3 mt-3">
      <v-btn class="ma-5" rounded @click="recyclebin()" color="secondary">
        <v-icon>mdi-delete-variant </v-icon>
        Recycle Bin
      </v-btn>
      <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href:
          window.location.protocol + "//" + window.location.hostname + ":8000/",
      },
      {
        text: "Shipto",
        disabled: false,
        href:
          window.location.protocol +
          "//" +
          window.location.hostname +
          ":8000/shipto",
      },
    ],
  }),
  computed: {
    ...mapGetters(["isAuth"]),
    ...mapState(["baseUrl"]),
  },
  methods:{
    recyclebin(){
      this.$router.push({ name: 'shipto.recyclebin' })
    }
  }
};
</script>