<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :options.sync="options"
      :server-items-length="totalDesserts"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:[`item.name`]="{ item }">
        <p
          v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']"
          class="mt-3"
        >
          {{ item.name }}
        </p>
      </template>
      <template v-slot:[`item.email`]="{ item }">
        <p
          v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']"
          class="mt-3"
        >
          {{ item.email }}
        </p>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <p
          v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']"
          class="mt-3"
        >
          {{ item.created_at }}
        </p>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <v-btn
          x-small
          outlined
          dense
          :color="item.type === 'front-office' ? 'indigo' : 'green'"
        >
          {{ item.type }}
        </v-btn>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="restore(item.id)" v-if="$can('user-delete')">
          mdi-restore
        </v-icon>
        <v-icon small class="mr-2" @click="forceDelete(item.id)" v-if="$can('user-delete')">
          mdi-delete-alert
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      totalDesserts: 0,
      desserts: [],
      loading: true,
      options: {},
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Email", value: "email" },
        { text: "User Type", value: "type" },
        { text: "Created At", value: "created_at" },
        { text: "#", value: "actions", sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    ...mapActions("user", [
        "RecycleBins",
        "restoreRecycle",
        "deleteRecycle"
    ]),
    getDataFromApi() {
      this.loading = true;
      const { page } = this.options;
      this.RecycleBins(page).then((data) => {
        this.desserts = data.data;
        this.totalDesserts = data.meta.total;
        this.loading = false;
      });
    },
    restore(e){
        this.restoreRecycle(e).then((res) => {
            if(res === 200){
                this.getDataFromApi();
            }
        });
    },
    forceDelete(e){
        this.deleteRecycle(e).then((res) => {
            if(res === 200){
                this.getDataFromApi();
            }
        });
    }
  },
};
</script>