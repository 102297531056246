<template>
    <v-form class="my-5" lazy-validation>
        <v-divider/>
        <v-text-field
            class="pt-0 ma-0"
            label="Enter your fullname.."
            v-model="form.name"
            rounded
            dense
            append-icon="mdi-account-circle"
            autocomplete="off"
            name="name"
            type="text"
            required
            outlined
        />
        <v-text-field
            class="pt-0 ma-0"
            label="Enter email address.."
            v-model="form.email"
            rounded
            dense
            append-icon="mdi-email"
            autocomplete="off"
            name="email"
            type="email"
            required
            outlined
        />
        <v-text-field
            class="pt-0 ma-0"
            label="Enter password.."
            v-model="form.password"
            rounded
            dense
            append-icon="mdi-lock"
            autocomplete="off"
            name="password"
            type="password"
            required
            outlined
        />
        <v-text-field
            class="pt-0 ma-0"
            label="Enter confirm password.."
            v-model="form.confirm_password"
            rounded
            dense
            append-icon="mdi-lock-check"
            autocomplete="off"
            name="password"
            type="password"
            required
            outlined
        />
        <v-row class="d-flex justify-space-between">
            <v-checkbox
                v-model="form.remember"
                :label="`Remember me: ${form.remember.toString()}`"
            />
            <v-btn
                class="mt-4"
                :loading="loading"
                @click="handleRegist"
                rounded
                color="primary"
                dark
            >
            Sign in
            </v-btn>
        </v-row>
    </v-form>
</template>
<script>
import { mapGetters,mapState,mapActions,mapMutations } from "vuex";
export default {
    computed:{
        ...mapGetters(['isAuth']),
        ...mapState([
            'loading',
        ]),
        ...mapState('auth', {
            form: state => state.formregist
        }),
    },
    methods:{
        ...mapActions('auth', ['submitRegister']),
        ...mapMutations(['CLEAR_ERRORS']),
        handleRegist(){
            this.submitRegister()
        }
    }
}
</script>