<template>
  <v-container>
    <v-row class="d-flex justify-content-end px-3 mt-3">
      <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
    </v-row>
    <v-row justify="center">
      <v-container fluid>
        <v-data-table
          dense
          :headers="headers"
          :items="desserts"
          :options.sync="options"
          :server-items-length="totalDesserts"
          :loading="loading"
        >
        <template v-slot:[`item.model_type`]="{ item }">
          <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.model_type }}</p>
        </template>
        <template v-slot:[`item.message`]="{ item }">
          <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.message }}</p>
        </template>
        <template v-slot:[`item.meta`]="{ item }">
          <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.meta }}</p>
        </template>
        <template v-slot:[`item.performed_at`]="{ item }">
          <p v-bind:class="[$vuetify.theme.dark ? 'text-light' : 'text-dark']" class="mt-3">{{ item.performed_at }}</p>
        </template>
        </v-data-table>
      </v-container>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  created(){
    this.getHistory()
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href:
          window.location.protocol + "//" + window.location.hostname + ":8000/",
      },
      {
        text: "Users",
        disabled: false,
        href:
          window.location.protocol +
          "//" +
          window.location.hostname +
          ":8000/users",
      },
    ],
    totalDesserts: 0,
    desserts: [],
    loading: true,
    options: {},
    headers: [
      {
        text: 'Models (type)',
        align: 'start',
        sortable: false,
        value: 'model_type',
      },
      { text: 'Message', value: 'message' },
      { text: 'Meta (data)', value: 'meta' },
      { text: 'Performed at', value: 'performed_at' },
    ],
  }),
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  mounted () {
    this.getDataFromApi()
  },
  computed: {
    ...mapGetters(["isAuth"]),
    ...mapState("auth", {
      history: (state) => state.history,
    }),
  },
  methods: {
    ...mapActions("auth", ["getHistory"]),
    getDataFromApi () {
      this.loading = true
      const { page } = this.options
      this.getHistory(page).then((res)=>{
        this.desserts = res.data
        this.totalDesserts = res.meta.total
        this.loading = false
      });
    },
  }
};
</script>