<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{
            id ? "Update promotion" : "Create promotion"
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-file-input
                      accept="image/png, image/jpeg, image/bmp"
                      input
                      show-size
                      label="File img"
                      v-model="form.img"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-switch
                      v-model="form.state"
                      :label="`State`"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
          <v-img :src="baseUrl + '/storage/img/promotion/'+img" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="id ? update(id) : create()">
            {{ id ? "Updated" : "Created" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState(["loading", "errors", "baseUrl"]),
    ...mapState("promotion", {
      dialog: (state) => state.dialog.show,
      id: (state) => state.dialog.id,
      form: (state) => state.form,
      img: (state) => state.img,
    }),
    dialog: {
      get: function () {
        return this.$store.state.promotion.dialog.show;
      },
      set: function (value) {
        this.$store.commit("promotion/SET_DIALOG", { show: value });
      },
    },
    id: {
      get: function () {
        return this.$store.state.promotion.dialog.id;
      },
      set: function (value) {
        this.$store.commit("promotion/SET_DIALOG", { id: value });
      },
    },
    img: {
      get: function () {
        return this.$store.state.promotion.img;
      },
      set: function (value) {
        this.$store.commit("promotion/SET_IMG_DISPLAY", value );
      },
    },
  },
  watch: {
    dialog(n) {
      if (n === false) {
        this.$store.commit("promotion/CLEAR_FORM");
      }
    },
  },
  methods: {
    ...mapActions("promotion", ["createOrUpdate"]),
    create() {
      this.createOrUpdate().then((response) => {
        if (response === 200) {
          this.$store.commit("promotion/SET_DIALOG", { show: false });
        }
      });
    },
    update(id) {
      this.createOrUpdate(id).then((response) => {
        if (response === 200) {
          this.$store.commit("promotion/SET_DIALOG", { show: false });
        }
      });
    },
  },
};
</script>