<template>
  <div class="home">
    <v-container class="mt-5">
      <v-row class="d-flex justify-content-end px-3 mt-3">
        <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
      </v-row>
      <DashboardAdmin v-if="authenticated.rolename==='Admin Dealers'||authenticated.rolename==='Marketing'"/>
      <DashboardSuperAdmin v-if="authenticated.rolename==='Superadmin'"/>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DashboardAdmin from './components/DashboardAdmin';
import DashboardSuperAdmin from './components/DashboardSuperAdmin';
export default {
  name: "Home",
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href:
          window.location.protocol + "//" + window.location.hostname + ":8000/",
      },
      {
        text: "Dashboard",
        disabled: false,
        href:
          window.location.protocol +
          "//" +
          window.location.hostname +
          ":8000/",
      },
    ],
  }),
  components:{
    DashboardAdmin,
    DashboardSuperAdmin
  },
  computed: {
    ...mapState("auth", {
      authenticated: (state) => state.authenticated,
    }),
  },
};
</script>
<style lang="sass" scoped>
.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.8)
  >.v-card__text
    color: #000
</style>
<style scoped>
.border {
  border-right: 1px solid grey;
}
</style>