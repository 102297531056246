<template>
  <div>
    <!-- starter row card ones -->
    <v-row>
      <v-col cols="12" md="6" sm="12">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card class="cardfo rounded-xl mb-5" :elevation="hover ? 16 : 2">
            <v-row>
              <v-col cols="12" sm="8">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="mb-4">
                      <v-btn fab color="cyan lighten-2" elevation="0">
                        <v-icon color="white">mdi-chart-bar </v-icon>
                      </v-btn>
                    </div>
                    <v-list-item-title class="headline mb-1 white--text">
                      Fixed Order Bulan Ini
                    </v-list-item-title>
                    <v-list-item-subtitle class="white--text">
                      ORDER : Rp.
                      {{
                        parseInt(foOrder)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      SUPPLY : Rp.
                      {{
                        parseInt(foSupply)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="4" v-if="$vuetify.breakpoint.mdAndUp">
                <v-avatar size="100" class="ml-n10 mt-6 text-center" tile>
                  <v-img
                    :src="
                      baseUrl + '/storage/img/Order Confirmation_Isometric.svg'
                    "
                  ></v-img>
                </v-avatar>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card class="cardro rounded-xl mb-5" :elevation="hover ? 16 : 2">
            <v-row>
              <v-col cols="12" sm="8">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="mb-4">
                      <v-btn fab color="cyan darken-1" elevation="0">
                        <v-icon color="white">mdi-chart-bar </v-icon>
                      </v-btn>
                    </div>
                    <v-list-item-title class="headline mb-1 white--text">
                      Regular Order Bulan Ini
                    </v-list-item-title>
                    <v-list-item-subtitle class="white--text">
                      ORDER : Rp.
                      {{
                        parseInt(roOrder)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      SUPPLY : Rp.
                      {{
                        parseInt(roSupply)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="4" v-if="$vuetify.breakpoint.mdAndUp">
                <v-avatar size="100" class="ml-n10 mt-6" tile>
                  <v-img
                    :src="
                      baseUrl +
                      '/storage/img/Order Confirmation_Monochromatic.svg'
                    "
                  ></v-img>
                </v-avatar>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row>
      <v-container>
        <v-card color="chart" class="rounded-xl card">
          <v-card-title>
            <v-row class="mt-6 mb-3">
              <v-col>
                <v-select
                  dense
                  v-model="formFilter.dealers"
                  :items="dealers"
                  item-text="name"
                  item-value="accountnum"
                  label="Dealers"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  dense
                  v-model="formFilter.tahun"
                  :items="year"
                  label="Tahun"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  dense
                  v-model="formFilter.semester"
                  :items="smt"
                  label="Semester"
                  @change="changeSMT(formFilter.semester)"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  dense
                  v-model="formFilter.kuartal"
                  :items="kuartal"
                  label="Kuartal"
                ></v-select>
              </v-col>
              <v-col>
                <v-btn
                  v-if="formFilter.dealers !=''"
                  rounded
                  small
                  outlined
                  :loading="chartLoading"
                  @click="filter()"
                >
                  Filter
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row v-if="loaded === false">
              <v-col cols="12" md="6">
                <line-chart :chartdata="chartdata" :options="options" />
              </v-col>
              <v-col cols="12" md="6">
                <bar-charts :chartdata="chartdata" :options="options" />
              </v-col>
            </v-row>
            <div class="text-center" v-else>
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-container>
    </v-row>
    <!-- ended row card ones -->
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import LineChart from "../../../components/charts/LineCharts.vue";
import BarCharts from "../../../components/charts/BarCharts.vue";
export default {
  name: "Home",
  computed: {
    ...mapState(["baseUrl"]),
  },
  components: {
    LineChart,
    BarCharts,
  },
  created() {
    var tahun = 2010;
    var tahunarray = [];
    for (var i = new Date().getFullYear(); i >= tahun; i--) {
      tahunarray.push(i);
    }
    this.year = tahunarray;
  },
  data() {
    return {
      formFilter: {
        dealers: "",
        tahun: "",
        semester: "",
        kuartal: "",
      },
      dealers: [],
      year: [],
      smt: [1, 2],
      kuartal: [],
      chartLoading: false,
      loaded: false,
      chartdata: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                callback: (value) => {
                  return `Rp. ${value
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              return (
                "Rp. " +
                parseInt(tooltipItem.yLabel)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              );
            },
          },
        },
      },

      foOrder: 0,
      roOrder: 0,
      foSupply: 0,
      roSupply: 0,
    };
  },
  async mounted() {
    this.getDataFromApi();
    this.getDealers();
  },
  methods: {
    ...mapActions("dashboard", [
      "chartCall",
      "getFOandRODealers",
      "getDealers",
    ]),
    getDataFromApi() {
      this.loaded = true;
      this.getDealers().then((res) => {
        this.dealers = res;
      });
      this.chartCall(this.formFilter).then((res) => {
        this.chartdata = res;
        this.loaded = false;
      });
      this.getFOandRODealers(this.formFilter).then((res) => {
        this.foOrder = res.foOrder;
        this.roOrder = res.roOrder;
        this.foSupply = res.foSupply;
        this.roSupply = res.roSupply;
      });
    },
    changeSMT(e) {
      if (e === 1) {
        this.kuartal = ["01", "02"];
      } else {
        this.kuartal = ["03", "04"];
      }
    },
    filter() {
      this.loaded = true;
      this.chartLoading = true;
      this.getFOandRODealers(this.formFilter).then((res) => {
        this.foOrder = res.foOrder;
        this.roOrder = res.roOrder;
        this.foSupply = res.foSupply;
        this.roSupply = res.roSupply;
      });
      this.chartCall(this.formFilter).then((res) => {
        this.chartdata = res;
        this.loaded = false;
        this.chartLoading = false;
      });
    },
  },
};
</script>