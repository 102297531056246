<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-center">
        <p>RINCIAN</p>
      </v-card-title>
      <v-card-text v-if="loading" class="text-center">
        <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
      </v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">PRODUCT GROUP</th>
              <th class="text-center">TARGET</th>
              <th class="text-center">QTY {{ bulan1($route.params.quartals) }}</th>
              <th class="text-center">{{ bulan1($route.params.quartals) }}</th>
              <th class="text-center">QTY {{ bulan2($route.params.quartals) }}</th>
              <th class="text-center">{{ bulan2($route.params.quartals) }}</th>
              <th class="text-center">QTY {{ bulan3($route.params.quartals) }}</th>
              <th class="text-center">{{ bulan3($route.params.quartals) }}</th>
              <th class="text-center">TOTAL FO Q4 2020</th>
              <th class="text-center">%TGT-ACH%</th>
              <th class="text-center">%INCENT</th>
              <th class="text-center">INCENTIVE</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="items in desserts_edit.INDOPART" :key="items.kelompok_produk">
              <td v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{ items.kelompok_produk }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.target)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_qty_first)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_amount_first)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_qty_second)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_amount_second)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_qty_thirth)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_amount_thirth)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.fo)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.tgt_ach)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}%
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.incent)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}%
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.incentive)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
            </tr>
            <tr v-for="items in desserts_edit.INDOBATTERY" :key="items.kelompok_produk">
              <td v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{ items.kelompok_produk }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.target)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_qty_first)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_amount_first)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_qty_second)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_amount_second)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_qty_thirth)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_amount_thirth)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.fo)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.tgt_ach)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}%
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.incent)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}%
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.incentive)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
            </tr>
            <tr v-for="items in desserts_edit.INDOTIRE" :key="items.kelompok_produk">
              <td v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{ items.kelompok_produk }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.target)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_qty_first)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_amount_first)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_qty_second)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_amount_second)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_qty_thirth)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_amount_thirth)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.fo)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.tgt_ach)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}%
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.incent)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}%
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.incentive)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
            </tr>
            <tr v-for="items in desserts_edit.TOTAL" :key="items.kelompok_produk">
              <td v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{ items.kelompok_produk }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.target)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_qty_first)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_amount_first)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_qty_second)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_amount_second)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_qty_thirth)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.total_amount_thirth)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.fo)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.tgt_ach)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}%
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.incent)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}%
              </td>
              <td class="text-right" v-bind:class="[
                $vuetify.theme.dark ? 'text-light' : 'text-dark',
              ]">
                {{
                    parseFloat(items.incentive)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState("quartals", {
      desserts_edit: (state) => state.desserts_edit,
    }),
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    ...mapActions("quartals", ["Edit", "Updated"]),
    ...mapActions("auth", ["logout"]),
    bulan1(e) {
      switch (e) {
        case '01-03':
          return 'JANUARI'
          break;
        case '04-06':
          return 'APRIL'
          break;
        case '07-09':
          return 'JULI'
          break;
        case '10-12':
          return 'OKTOBER'
          break;
      }
    },
    bulan2(e) {
      switch (e) {
        case '01-03':
          return 'FEBRUARI'
          break;
        case '04-06':
          return 'MEI'
          break;
        case '07-09':
          return 'AGUSTUS'
          break;
        case '10-12':
          return 'NOVEMBER'
          break;
      }
    },
    bulan3(e) {
      switch (e) {
        case '01-03':
          return 'MARET'
          break;
        case '04-06':
          return 'JUNI'
          break;
        case '07-09':
          return 'SEPTEMBER'
          break;
        case '10-12':
          return 'DESEMBER'
          break;
      }
    },
    getDataFromApi() {
      this.loading = true;
      const params = {
        dealers: this.$route.params.dealer,
        quartals: this.$route.params.quartals,
        year: this.$route.params.years,
      };
      this.Edit(params).then((e) => {
        this.loading = false;
        if (e != 200) {
          this.logout().then((r) => {
            this.$router.push({ name: "auth.login" });
          });
        }
      });
    },
  },
};
</script>
